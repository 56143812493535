import { Edit, FileCopy, GetApp } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import {
  BottomNavigation,
  Box,
  Button,
  ButtonProps,
  Menu,
  MenuList,
  styled,
  Tooltip,
  Typography,
  Chip,
} from '@mui/material';
import MenuItem from '@mui/material/MenuItem';
import useDeliverableRights from 'hooks/rights/useDeliverableRights';
import useUserRoles from 'hooks/useUserRoles';
import React, { useCallback, useMemo, useState, useEffect, MouseEvent, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { selectAllDeliverablesAction, selectDeliverablesAction } from 'store/actions/deliverableActions';

import { openDialogAction } from 'store/actions/dialogsActions';
import { openDrawerAction } from 'store/actions/drawerActions';
import { addLoadingAction, removeLoadingAction } from 'store/actions/loadingsActions';
import { useSelector } from 'store/hooks';
import { useAppState } from 'store/Provider';
import { selectSelectedDeliverables as duplicateSelectSelectedDeliverables } from 'store/selectors/deliverableSelectors';
import { DeliverableTableType } from 'types/enums/DeliverableTableType';
import useDeliverableService from 'features/Deliverables/hooks/useDeliverableService';
import { MissionAdvancementPossibilities } from 'types/enums/MissionAdvancementPossibilities';
import OrderWorkunit from 'types/entities/OrderWorkunit';
import { setSnackbarAction } from 'store/actions/snackbarActions';
import MissionComment from 'types/entities/MissionComment';
import RatingPopup from './RatingPopup';

type QuickActionKey =
  | 'delete'
  | 'submission'
  | 'cancelsubmission'
  | 'validation'
  | 'cancelvalidation'
  | 'acceptation'
  | 'cancellation'
  | 'deliverablesassessment';

type QuickActions = {
  cancellation: boolean;
  cancelsubmission: boolean;
  cancelvalidation: boolean;
  submission: boolean;
  validation: boolean;
  acceptation: boolean;
  delete: boolean;
  eval?: 'A' | 'B' | 'C' | 'D' | 'S' | null;
  comment?: MissionComment | null;
};

type QuickActionsAffectations = {
  wu_parameters?: number;
  custom_wu_parameters?: number;
  ordered_wu_parameters?: number;
  cancellation: number;
  cancelsubmission: number;
  cancelvalidation: number;
  submission: number;
  validation: number;
  acceptation: number;
  delete: number;
  eval?: number;
};

type QuickActionStatusDisabled = {
  wu_parameters?: boolean;
  custom_wu_parameters?: boolean;
  ordered_wu_parameters?: boolean;
  cancellation: boolean;
  cancelsubmission: boolean;
  cancelvalidation: boolean;
  submission: boolean;
  validation: boolean;
  acceptation: boolean;
  delete: boolean;
  eval?: boolean;
};

const DeliverableQuickActions: React.FC = () => {
  type MenuAction =
    | 'submitDeliverables'
    | 'cancelSubmission'
    | 'validateDeliverables'
    | 'acceptDeliverables'
    | 'cancelDeliverables'
    | 'cancelValidation'
    | 'deleteDeliverables'
    | 'deliverablesAssessment';

  const [t] = useTranslation();
  const { dispatch } = useAppState();
  const {
    getDeliverableByListId,
    deleteDeliverables,
    cancelDeliverables,
    changeMissionAdvancement,
    setRatingDeliverables,
    addCommentDeliverable,
  } = useDeliverableService();
  const loadings = useSelector((state) => state.loadings);
  const appState = useSelector((state) => state.app);
  const state = useSelector((state) => state.deliverable);
  const clientConnection = useSelector((state) => state.deliverable.order?.client_connection);
  const [selectedDeliverables, selectSelectedDeliverables] = useState<OrderWorkunit[]>([]);
  const { canValidate, canSubmit, canCancel, canManagerRate, canManagerAccept } =
    useDeliverableRights(selectedDeliverables);
  const [quickActions, setQuickActions] = useState<QuickActions>({
    cancellation: false,
    cancelsubmission: false,
    cancelvalidation: false,
    submission: false,
    validation: false,
    acceptation: false,
    delete: false,
  });
  const [quickActionsAffectation, setQuickActionsAffectation] = useState<QuickActionsAffectations>({
    cancellation: 0,
    cancelsubmission: 0,
    cancelvalidation: 0,
    submission: 0,
    validation: 0,
    acceptation: 0,
    delete: 0,
    eval: 0,
  });
  const [quickActionStatusDisabled, setQuickActionStatusDisabled] = useState<QuickActionStatusDisabled>({
    cancellation: true,
    cancelsubmission: true,
    cancelvalidation: true,
    submission: true,
    validation: true,
    acceptation: true,
    delete: true,
    eval: false,
  });

  const selectedDeliverablesForDuplicate = useSelector(duplicateSelectSelectedDeliverables);
  const [cancelReason, setCancelReason] = useState<string | null>(null);
  const [event, setEvent] = useState<MouseEvent | null>(null);
  const cancellationLabel = { inputProps: { 'aria-label': t('Cancel_selected_deliverables') } };
  const cancellationSubmissionLabel = { inputProps: { 'aria-label': t('Cancel_submission_selected_deliverables') } };
  const cancellationValidationLabel = { inputProps: { 'aria-label': t('Cancel_validation_selected_deliverables') } };
  const userIsAdmin = appState.roles?.includes('admin');
  const userIsConsultant = appState.roles?.toString().includes('consultant');
  const userIsBm = appState.roles?.toString().includes('bm');
  const userIsTl = appState.roles?.toString().includes('team_leader');
  const userIsTlPlus = appState.roles?.toString().includes('team_leader_plus');
  const userIsCustomer = appState.user?.is_client; // TODO use customer roles ?
  const canDeliverablesBeEvaluated = selectedDeliverables.every(
    (mission) =>
      mission.mission_advancement_id &&
      mission.mission_advancement_id >= MissionAdvancementPossibilities.RESERVE_CLIENT &&
      !mission.rating
  );
  const userRights = useUserRoles();
  const { generateExcelReport, generateExcelExtract, duplicateDeliverables, isTnM } = useDeliverableService();
  const { canUseQuickActions, canEdit, canExport, canExportGR, canCreateDeliverableSheet, canDuplicate } =
    useDeliverableRights(state.selectedAllDeliverables && !userRights.isClient ? [] : selectedDeliverables);
  const isThereDuplicableDeliverables = selectedDeliverablesForDuplicate.some((deliv) => !deliv.workunit_id);
  const isEveryDeliverableDuplicable = selectedDeliverablesForDuplicate.every((deliv) => !deliv.workunit_id);
  const [triggerSubmit, setTriggerSubmit] = useState(false);
  const [isRatingPopupOpen, setIsRatingPopupOpen] = useState<boolean>(false);

  const [anchorPosition, setAnchorPosition] = useState<{ top: number; left: number } | null>(null);

  const [menuAction, setMenuAction] = useState<MenuAction | null>(null);
  const [isMenuVisible, setIsMenuVisible] = useState(false);

  const selectModeAllDeliverables = useSelector((state) => state.deliverable.selectedAllDeliverables);
  const quickActionsButtonRef = useRef<HTMLButtonElement>(null);

  const orderType = useSelector((state) => state.deliverable.order?.order_type_id);

  const getSelectedDeliverableByListId = useCallback(async () => {
    // console.log('getDeliverableByListId called in useCallback');
    const deliverables = await getDeliverableByListId(
      state?.order?.id as number,
      state.selectedDeliverablesModel as number[]
    );
    selectSelectedDeliverables(deliverables);
  }, [getDeliverableByListId, state?.order?.id, state.selectedDeliverablesModel]);

  useEffect(() => {
    // console.log('getSelectedDeliverableByListId useEffect called');
    getSelectedDeliverableByListId();
  }, [getSelectedDeliverableByListId, state.selectedDeliverablesModel, state?.order?.id, isMenuVisible]);

  useEffect(() => {
    setQuickActionStatusDisabled({
      cancellation: canCancel,
      cancelsubmission: canCancelSubmission(),
      cancelvalidation: canCancelValidation(),
      submission: canSubmit,
      validation: canValidate,
      acceptation: isAcceptDisabled(),
      delete: isDeleteDeliverableDisable(),
      eval: canUserEvaluateDeliverables(),
    });
    if (!state.selectedAllDeliverables) {
      setQuickActionsAffectation({
        ...quickActionsAffectation,
        cancellation: countCancellableMissions(),
        submission: countSubmittableDeliverable(),
        validation: countValidableDeliverables(),
        cancelsubmission: countCancellableSubmittedDeliverables(),
        cancelvalidation: countCancellableValidatedDeliverables(),
        delete: countDeletableDeliverable(),
      });
    }
    // console.log('quickActionStatusDisabled inner:', quickActionStatusDisabled);
  }, [selectedDeliverables, quickActions]);

  useEffect(() => {
    if (triggerSubmit && event) {
      submit(event);
      setTriggerSubmit(false);
    }
  }, [triggerSubmit]);

  const resetQuickAction = (actionKey: QuickActionKey) => {
    setQuickActions((prevQuickActions) => ({ ...prevQuickActions, [actionKey]: false }));
  };

  const countCancellableMissions = () => {
    let count = 0;
    if (userIsAdmin || userIsBm || userIsTl || userIsTlPlus) {
      count = selectedDeliverables.filter(
        (mission) =>
          !mission.mission_advancement_id || mission.mission_advancement_id < MissionAdvancementPossibilities.RESERVE_TL
      ).length;
    }
    if (userIsConsultant) {
      count = selectedDeliverables.filter((mission) => !mission.mission_advancement_id).length;
    }
    return count;
  };

  function anyDeliverableHasUndefinedId(deliverables: OrderWorkunit[]) {
    return deliverables.some((deliverable) => !deliverable.consultant?.id || !deliverable.client?.id);
  }

  // Check if any deliverable meets the acceptance status criteria
  function anyDeliverableAboveAcceptanceStatus(deliverables: OrderWorkunit[]) {
    return deliverables.some(
      (deliverable) =>
        deliverable.mission_advancement_id &&
        deliverable.mission_advancement_id >= MissionAdvancementPossibilities.ACCEPTATION_TL &&
        (!deliverable.consultant?.id || !deliverable.client?.id)
    );
  }

  const handleActions = async (item: any) => {
    if (item.loadingName) dispatch(addLoadingAction(`action${item.loadingName}`));
    await item.action();
    if (item.loadingName) dispatch(removeLoadingAction(`action${item.loadingName}`));
  };

  const exportExcelGR = useCallback(async () => {
    await generateExcelReport('financial-reports');
  }, [generateExcelReport]);

  const exportExcelReport = useCallback(async () => {
    await generateExcelReport('deliverables');
  }, [generateExcelReport]);

  const createSheetReport = useCallback(async () => {
    dispatch(openDialogAction({ name: 'deliverableCreateReportConfirm' }));
  }, [dispatch]);

  const openEditDeliverableDrawer = useCallback(async () => {
    dispatch(
      openDrawerAction({
        name: 'deliverableEdit',
        data: state.selectedDeliverablesModel,
      })
    );
  }, [dispatch, state.selectedDeliverablesModel]);

  const duplicateDeliverable = useCallback(async () => {
    duplicateDeliverables(selectedDeliverables);
  }, [duplicateDeliverables, selectedDeliverables]);

  const canCancelSubmission = () => {
    if (state.selectedAllDeliverables) {
      return true;
    }
    if (userIsAdmin || userIsBm || userIsTl || userIsTlPlus || userIsConsultant) {
      if (countClientAcceptanceRecords() > 0) {
        return true;
      }
      if (userIsConsultant) {
        return selectedDeliverables.some(
          (m) => m.mission_advancement_id && m.mission_advancement_id > MissionAdvancementPossibilities.SUBMITTED
        );
      }
      return !selectedDeliverables.some(
        (m) => m.mission_advancement_id && m.mission_advancement_id === MissionAdvancementPossibilities.SUBMITTED
      );
    }
    return false;
  };

  const canCancelValidation = () => {
    if (state.selectedAllDeliverables) {
      return true;
    }

    if (userIsAdmin || userIsBm || userIsTl || userIsTlPlus) {
      if (countClientAcceptanceRecords() > 0) {
        return true;
      }
      return !selectedDeliverables.some(
        (m) =>
          m.mission_advancement_id &&
          m.mission_advancement_id >= MissionAdvancementPossibilities.RESERVE_TL &&
          m.mission_advancement_id <= MissionAdvancementPossibilities.ACCEPTATION_TL
      );
    }
    return false;
  };

  const isAcceptDisabled = () => {
    if (state.selectedAllDeliverables) {
      return true;
    }
    if (userIsCustomer || (!clientConnection && canManagerAccept)) {
      return !selectedDeliverables.every(
        (m) =>
          m.mission_advancement_id &&
          m.mission_advancement_id >= MissionAdvancementPossibilities.RESERVE_TL &&
          ![
            MissionAdvancementPossibilities.REFUSED_CLIENT,
            MissionAdvancementPossibilities.RESERVE_CLIENT,
            MissionAdvancementPossibilities.ACCEPTATION_CLIENT,
          ].includes(m.mission_advancement_id)
      );
    }
    return true;
  };

  const isDeleteDeliverableDisable = () => {
    if (state.selectedAllDeliverables) {
      return true;
    }
    let returnFlag1 = true;
    let returnFlag2 = true;
    let returnFlag3 = true;
    if (userIsConsultant)
      returnFlag1 = !selectedDeliverables.every(
        (d) =>
          !d.mission_advancement_id &&
          !d.origin.includes('quote') &&
          !d.origin.includes('custom') &&
          !d.origin.includes('catalog') &&
          !d.label?.includes('added')
      );

    // console.log(`returnFlag1 : ${returnFlag1}`);

    if (userIsBm || userIsTl || userIsTlPlus || userIsAdmin) {
      returnFlag2 = !selectedDeliverables.every(
        (d) =>
          !d.origin.includes('quote') &&
          !d.origin.includes('custom') &&
          !d.origin.includes('catalog') &&
          !d.label?.includes('added') &&
          (!d.mission_advancement_id ||
            (d.mission_advancement_id && d.mission_advancement_id > MissionAdvancementPossibilities.SUBMITTED))
      );
      // console.log(`returnFlag2 : ${returnFlag2}`);
    }
    returnFlag3 = !selectedDeliverables.every(
      (d) =>
        !d.mission_advancement_id &&
        (d.origin.includes('custom') || (d.origin.includes('catalog') && d.label?.includes('added')))
    );

    // console.log(`returnFlag3 : ${returnFlag3}`);

    if (!returnFlag1) return returnFlag1;
    if (!returnFlag2) return returnFlag2;
    return returnFlag3;
  };

  const canUserEvaluateDeliverables = () => {
    if (state.selectedAllDeliverables) {
      return true;
    }
    if (userIsCustomer) {
      return selectedDeliverables.every(
        (m) =>
          m.mission_advancement_id &&
          m.mission_advancement_id >= MissionAdvancementPossibilities.RESERVE_CLIENT &&
          (!m.rating || m.rating === '')
      );
    }
    return true;
  };

  const countDeletableDeliverable = () =>
    selectedDeliverables.filter((m) => {
      if (m.origin === 'quote') return false;
      if (m.origin.toLowerCase() === 'custom' || m.label?.toLowerCase() === 'added') {
        if (m.mission_advancement_id && m.mission_advancement_id >= MissionAdvancementPossibilities.SUBMITTED)
          return false;
      }
      if (m.mission_advancement_id && m.mission_advancement_id < MissionAdvancementPossibilities.RESERVE_TL)
        if (userIsAdmin || userIsTl || userIsBm || userIsTlPlus) return true;
      if (!m.mission_advancement_id) {
        if (userIsConsultant && m.consultant?.id === appState.user?.id) {
          return true;
        }
        if (userIsAdmin || userIsTl || userIsBm || userIsTlPlus) return true;
      }
      return false;
    }).length;

  const countSubmittableDeliverable = () => {
    let count = 0;
    if (userIsAdmin || userIsBm || userIsTl || userIsConsultant) {
      count = selectedDeliverables.filter(
        (m) => !m.mission_advancement_id || m.mission_advancement_id === MissionAdvancementPossibilities.REFUSED_TL
      ).length;
    }
    return count;
  };

  const countCancellableSubmittedDeliverables = () => {
    let count = 0;
    if (userIsAdmin || userIsBm || userIsTl || userIsTlPlus || userIsConsultant) {
      count = selectedDeliverables.filter(
        (m) => m.mission_advancement_id && m.mission_advancement_id === MissionAdvancementPossibilities.SUBMITTED
      ).length;
    }
    return count;
  };

  const countCancellableValidatedDeliverables = () => {
    let count = 0;
    if (userIsAdmin || userIsBm || userIsTl || userIsTlPlus) {
      count = selectedDeliverables.filter(
        (m) =>
          m.mission_advancement_id &&
          (m.mission_advancement_id === MissionAdvancementPossibilities.ACCEPTATION_TL ||
            m.mission_advancement_id === MissionAdvancementPossibilities.RESERVE_TL)
      ).length;
    }
    return count;
  };

  const countValidableDeliverables = () => {
    let count = 0;
    if (userIsAdmin || userIsBm || userIsTl) {
      count = selectedDeliverables.filter(
        (m) => m.mission_advancement_id && m.mission_advancement_id === MissionAdvancementPossibilities.SUBMITTED
      ).length;
    }
    return count;
  };

  const countClientAcceptanceRecords = () => {
    let count = 0;
    if (userIsAdmin || userIsBm || userIsTl || userIsTlPlus || userIsConsultant) {
      count = selectedDeliverables.filter(
        (m) => m.mission_advancement_id && m.mission_advancement_id > MissionAdvancementPossibilities.ACCEPTATION_TL
      ).length;
    }
    return count;
  };

  const actions = useMemo(
    () => [
      {
        action: duplicateDeliverable,
        permission: canDuplicate && !selectModeAllDeliverables && isThereDuplicableDeliverables,
        label: t('Duplicate'),
        loadingName: 'duplicateDeliverable',
        icon: <FileCopy />,
        tooltip: !isEveryDeliverableDuplicable ? t('PARTIAL_DUPLICATE') : '',
      },
      {
        action: generateExcelExtract,
        permission: selectedDeliverables.length > 0 || selectModeAllDeliverables,
        label: t('Generate_sheet'),
        loadingName: 'generateExcelExtract',
        icon: <GetApp />,
      },
      {
        action: exportExcelGR,
        permission: canExportGR && !isTnM(),
        label: t('Export GR excel'),
        loadingName: 'exportExcelGR',
      },
      {
        action: exportExcelReport,
        permission: state.currentTableType === DeliverableTableType.TECHNICAL && canExport,
        label: t('Export excel report'),
        loadingName: 'exportExcelReport',
        icon: <Edit />,
      },
      /* {
        action: createSheetReport,
        permission: state.currentTableType === DeliverableTableType.TECHNICAL && canCreateDeliverableSheet,
        label: t('Create report'),
        loadingName: 'createSheetReport',
      }, */
      {
        action: openEditDeliverableDrawer,
        permission: state.currentTableType === DeliverableTableType.TECHNICAL && canEdit,
        label: t('Mass edit'),
      },
    ],
    [
      exportExcelGR,
      canExportGR,
      t,
      exportExcelReport,
      state.currentTableType,
      canExport,
      createSheetReport,
      canCreateDeliverableSheet,
      openEditDeliverableDrawer,
      canEdit,
      canUseQuickActions,
      isThereDuplicableDeliverables,
      generateExcelExtract,
      isTnM,
      canDuplicate,
      selectModeAllDeliverables,
      isEveryDeliverableDuplicable,
    ]
  );

  const NavigationBottom = styled(LoadingButton)<ButtonProps>(({ theme }) => ({
    color: theme.palette.primary.main,
    backgroundColor: '#FFFFFF',
    borderColor: '#FFFFFF',
    '&:hover': {
      backgroundColor: '#FFFFFF',
    },
  }));

  const actionsAvailables = useMemo(() => actions.filter((item) => item.permission !== false), [actions]);
  const selectedItems = () => {
    if (state.selectedAllDeliverables) {
      if (state.selectedDeliverablesModel.length > 0) {
        return `${state.totalItems - state.selectedDeliverablesModel.length} items selected (${
          state.selectedDeliverablesModel.length
        } excluded)`;
      }
      return `All selected (${state.totalItems} items)`;
    }

    return `${state.selectedDeliverablesModel.length} ${t('items_selected')}`;
  };

  const handleOpen = useCallback(
    (event) => {
      event.preventDefault();
      if (quickActionsButtonRef.current) {
        const rect = quickActionsButtonRef.current.getBoundingClientRect();
        const userIsClient = appState?.user?.is_client ?? false;
        if (!userIsClient) {
          setAnchorPosition({
            top: rect.bottom - 320,
            left: rect.left,
          });
        } else {
          setAnchorPosition({
            top: rect.bottom - 180,
            left: rect.left,
          });
        }

        setIsMenuVisible(true);
      }
    },
    [appState, quickActionsButtonRef]
  );

  const handleClose = useCallback(() => {
    setAnchorPosition(null);
    setIsMenuVisible(false);
  }, []);

  const handleMenuItemClick = (action: MenuAction, event: MouseEvent) => {
    // Handle menu item action here
    // console.log(`Action: ${action}`);
    switch (action) {
      case 'deleteDeliverables':
        handleDeleteDeliverables(event);
        break;
      case 'submitDeliverables':
        handleSubmitDeliverables(event);
        break;
      case 'cancelSubmission':
        handleCancelSubmission(event);
        break;
      case 'validateDeliverables':
        handleValidateDeliverables(event);
        break;
      case 'cancelValidation':
        handleCancelValidation(event);
        break;
      case 'acceptDeliverables':
        handleAcceptanceDeliverables(event);
        break;
      case 'cancelDeliverables':
        handleCancelDeliverables(event);
        break;
      case 'deliverablesAssessment':
        handleDeliverablesAssessment(event);
        break;
      default:
        console.log('handleMenuItemClick');
    }

    handleClose();
  };

  const submit = async (event: MouseEvent) => {
    if (state.selectedAllDeliverables) {
      return;
    }

    // ED-36 changes
    let isClientDeliverableAboveAcceptanceStatus = false;
    if (userIsCustomer) {
      isClientDeliverableAboveAcceptanceStatus = anyDeliverableAboveAcceptanceStatus(selectedDeliverables);

      if (!isClientDeliverableAboveAcceptanceStatus) {
        isClientDeliverableAboveAcceptanceStatus = true;
      }
    }

    const anyIsUndefinedId = anyDeliverableHasUndefinedId(selectedDeliverables);
    if (
      !quickActions?.cancellation &&
      anyIsUndefinedId &&
      !quickActions?.cancelsubmission &&
      !quickActions.cancelvalidation &&
      !isClientDeliverableAboveAcceptanceStatus
    ) {
      dispatch(
        setSnackbarAction({
          message:
            'The selected deliverable must have a consultant and client assigned before submitting. Please add the missing assignments.',
          open: true,
          severity: 'info',
        })
      );
      setTriggerSubmit(false);
      return;
    }

    const orderWorkunitsIds = state.selectedDeliverablesModel as OrderWorkunit['id'][];

    if (quickActions.submission) {
      await changeMissionAdvancement(
        orderWorkunitsIds,
        MissionAdvancementPossibilities.SUBMITTED,
        true,
        state.selectedAllDeliverables
      );
      resetQuickAction('submission');
    }

    if (quickActions.delete) {
      await deleteDeliverables(orderWorkunitsIds, state.selectedAllDeliverables);
      resetQuickAction('delete');
    }

    if (quickActions.validation) {
      await changeMissionAdvancement(
        orderWorkunitsIds,
        MissionAdvancementPossibilities.ACCEPTATION_TL,
        true,
        state.selectedAllDeliverables
      );
      resetQuickAction('validation');
      // console.log('quickActions.validation');
    }

    if (quickActions.acceptation) {
      await changeMissionAdvancement(
        orderWorkunitsIds,
        MissionAdvancementPossibilities.ACCEPTATION_CLIENT,
        true,
        state.selectedAllDeliverables
      );
      resetQuickAction('acceptation');
      // console.log('quickActions.acceptation');
    }

    if (quickActions.eval) {
      if (quickActions.acceptation) {
        // console.log('quickActions.acceptation entering here');
        const deliverablesRatable = selectedDeliverables.map((d) => ({
          ...d,
          mission_advancement_id: MissionAdvancementPossibilities.ACCEPTATION_CLIENT,
        }));
        if (quickActions.comment && quickActions.comment.comment !== undefined) {
          await Promise.all(
            deliverablesRatable.map(async (deliverable) => {
              if (quickActions.comment) {
                await addCommentDeliverable(deliverable, {
                  comment: quickActions.comment.comment,
                  is_private: quickActions.comment.is_private ?? false,
                });
              }
            })
          );
        }

        await setRatingDeliverables(deliverablesRatable, quickActions.eval, null, state.selectedAllDeliverables);
        setQuickActions((prevActions) => ({
          ...prevActions,
          eval: null,
        }));

        // console.log('setRatingDeliverables');
      } else {
        if (quickActions.comment && quickActions.comment.comment !== undefined) {
          await Promise.all(
            selectedDeliverables.map(async (deliverable) => {
              if (quickActions.comment) {
                await addCommentDeliverable(deliverable, {
                  comment: quickActions.comment.comment,
                  is_private: quickActions.comment.is_private ?? false,
                });
              }
            })
          );
        }
        // console.log('quickActions.comment: ', quickActions.comment);
        // console.log('enetering else part setRatingDeliverables');
        await setRatingDeliverables(selectedDeliverables, quickActions.eval, null, state.selectedAllDeliverables);
        setQuickActions((prevActions) => ({
          ...prevActions,
          eval: null,
        }));
      }
    }

    if (
      (quickActions.cancelsubmission && quickActions.cancelvalidation && !quickActions?.cancellation) ||
      (quickActions.cancelsubmission && countCancellableValidatedDeliverables() > 0)
    ) {
      event.preventDefault();
      const confirmOk = async () => {
        await changeMissionAdvancement(orderWorkunitsIds, null);
      };
      dispatch(
        openDialogAction({
          name: 'deliverableSubmissionCancellation',
          data: { confirm: confirmOk },
        })
      );
    } else if (quickActions.cancelsubmission) {
      await changeMissionAdvancement(orderWorkunitsIds, null);
      setQuickActions((prevQuickActions) => ({ ...prevQuickActions, cancelsubmission: false }));
    } else if (quickActions.cancelvalidation) {
      if (countCancellableSubmittedDeliverables() > 0 || countCancellableMissions() > 0) {
        dispatch(
          setSnackbarAction({
            message:
              'Cancel validation could not be done as there are other selected submitted/cancelled deliverables.',
            open: true,
            severity: 'info',
          })
        );
        setTriggerSubmit(false);
        return;
      }
      await changeMissionAdvancement(orderWorkunitsIds, MissionAdvancementPossibilities.SUBMITTED);
      resetQuickAction('cancelvalidation');
    }

    if (quickActions?.cancellation && cancelReason) {
      const orderWorkunitsIds = selectedDeliverables.map((orderWorkunit) => orderWorkunit.id);
      await cancelDeliverables(orderWorkunitsIds, cancelReason, state.selectedAllDeliverables);
      resetQuickAction('cancellation');
    }
    setTriggerSubmit(false);
  };

  const handleQuickAction = (actionKey: QuickActionKey, message: string, title: string) => {
    const confirmOk = async (cancelReason?: string) => {
      setQuickActions((prevQuickActions) => {
        const newQuickActions = { ...prevQuickActions, [actionKey]: true };
        return newQuickActions;
      });
      if (cancelReason) {
        setCancelReason(cancelReason);
      }

      setTriggerSubmit(true);
    };

    dispatch(
      openDialogAction({
        name: 'deliverableConfirmationDialog',
        data: {
          message,
          title,
          confirm: confirmOk,
        },
      })
    );
  };

  const handleDeleteDeliverables = (event: MouseEvent) => {
    setEvent(event);
    handleQuickAction('delete', 'Are you sure to delete the selected deliverables?', 'Delete Deliverables');
  };

  const handleSubmitDeliverables = (event: MouseEvent) => {
    setEvent(event);
    handleQuickAction('submission', 'Submit the selected deliverables ?', 'Submit Deliverables');
  };

  const handleCancelSubmission = (event: MouseEvent) => {
    setEvent(event);
    handleQuickAction('cancelsubmission', 'Are you sure to cancel the submission ?', 'Cancel Submission');
  };

  const handleValidateDeliverables = (event: MouseEvent) => {
    setEvent(event);
    handleQuickAction('validation', 'Validate the selected deliverables ?', 'Validate Deliverables');
  };

  const handleCancelValidation = (event: MouseEvent) => {
    setEvent(event);
    handleQuickAction('cancelvalidation', 'Are you sure to cancel validation ?', 'Cancel Validation');
  };

  const handleAcceptanceDeliverables = (event: MouseEvent) => {
    setEvent(event);
    handleQuickAction('acceptation', 'Accept the selected deliverables ?', 'Accept Deliverables');
  };

  const handleCancelDeliverables = (event: MouseEvent) => {
    setEvent(event);
    handleQuickAction('cancellation', 'Are you sure to cancel the selected deliverables ?', 'Cancel Deliverables');
  };

  const handleDeliverablesAssessment = (event: MouseEvent) => {
    setEvent(event);
    setIsRatingPopupOpen(true);
  };
  // console.log(`quickActionStatusDisabled.submission `, quickActionStatusDisabled.submission);

  const handleCloseRatingPopup = () => {
    setIsRatingPopupOpen(false);
  };
  const handleSubmitRatingPopup = () => {
    setTriggerSubmit(true);
  };

  const isLoadingApi = !!loadings.api;

  return (
    <>
      {canUseQuickActions &&
        actionsAvailables.length > 0 &&
        (!!state.selectedDeliverablesModel.length || state.selectedAllDeliverables) && (
          <BottomNavigation
            sx={{
              borderRadius: '12px',
              backgroundColor: 'primary.main',
              position: 'fixed',
              flexDirection: { xs: 'column', md: 'row' },
              bottom: '10px',
              right: '25px',
              left: { xs: '25px', md: '170px' },
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              p: 2,
              color: '#FFFFFF',
              height: 'auto',
            }}
          >
            <Typography sx={{ fontWeight: 500, mb: { xs: 1, md: 0 } }}>{selectedItems()}</Typography>
            <Tooltip title="Affect every page">
              <NavigationBottom
                onClick={() => {
                  dispatch(selectAllDeliverablesAction(false));
                  dispatch(selectDeliverablesAction([]));
                }}
                sx={{ ml: 1, mb: { xs: 1, lg: 0 } }}
                size="small"
              >
                {t('Unselect all')}
              </NavigationBottom>
            </Tooltip>
            <Box
              sx={{ ml: 'auto', display: 'flex', justifyContent: { xs: 'center', md: 'flex-end' }, flexWrap: 'wrap' }}
            >
              {actionsAvailables.map((item) => (
                <NavigationBottom
                  key={item.label}
                  onClick={() => handleActions(item)}
                  sx={{ ml: 1, mb: { xs: 1, lg: 0 } }}
                  size="small"
                  loading={!!loadings[`action${item.loadingName}`]}
                >
                  {item.label}
                </NavigationBottom>
              ))}
              {!state.selectedAllDeliverables && (
                <NavigationBottom
                  ref={quickActionsButtonRef}
                  aria-label="settings"
                  aria-controls="simple-menu"
                  aria-haspopup="true"
                  onClick={handleOpen}
                  sx={{ ml: 1, mb: { xs: 1, lg: 0 } }}
                  size="small"
                >
                  Quick actions
                </NavigationBottom>
              )}
              <Menu
                anchorReference="anchorPosition"
                anchorPosition={
                  anchorPosition !== null ? { top: anchorPosition.top, left: anchorPosition.left } : undefined
                }
                open={Boolean(anchorPosition)}
                onClose={handleClose}
              >
                <MenuList>
                  {!userIsCustomer && (
                    <MenuItem
                      onClick={(event) => handleMenuItemClick('submitDeliverables', event)}
                      disabled={isLoadingApi || !quickActionStatusDisabled.submission}
                    >
                      <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <Box sx={{ mr: 1 }}>{t<string>('Deliverable_submission')}</Box>
                        {quickActionsAffectation.submission !== 0 && (
                          <Chip
                            size="small"
                            color="primary"
                            label={quickActionsAffectation.submission}
                            sx={{ fontSize: '1rem', fontWeight: 500 }}
                          />
                        )}
                      </Box>
                    </MenuItem>
                  )}
                  {(userIsAdmin || userIsBm || userIsTl || userIsTlPlus || userIsConsultant) && (
                    <MenuItem
                      onClick={(event) => handleMenuItemClick('cancelSubmission', event)}
                      disabled={isLoadingApi || quickActionStatusDisabled.cancelsubmission}
                    >
                      <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <Box sx={{ mr: 1 }}>{t<string>('Cancel_submission')}</Box>
                        {quickActionsAffectation.cancelsubmission !== 0 && (
                          <Chip
                            size="small"
                            color="primary"
                            label={quickActionsAffectation.cancelsubmission}
                            sx={{ fontSize: '1rem', fontWeight: 500 }}
                          />
                        )}
                      </Box>
                    </MenuItem>
                  )}
                  {!userIsCustomer && !userIsConsultant && (
                    <MenuItem
                      onClick={(event) => handleMenuItemClick('validateDeliverables', event)}
                      disabled={isLoadingApi || !quickActionStatusDisabled.validation}
                    >
                      <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <Box sx={{ mr: 1 }}>{t<string>('Deliverables_validation')}</Box>
                        {quickActionsAffectation.validation !== 0 && (
                          <Chip
                            size="small"
                            color="primary"
                            label={quickActionsAffectation.validation}
                            sx={{ fontSize: '1rem', fontWeight: 500 }}
                          />
                        )}
                      </Box>
                    </MenuItem>
                  )}
                  {(userIsCustomer || (!clientConnection && canManagerAccept)) && (
                    <MenuItem
                      onClick={(event) => handleMenuItemClick('acceptDeliverables', event)}
                      disabled={isLoadingApi || quickActionStatusDisabled.acceptation}
                    >
                      Accept Deliverables
                    </MenuItem>
                  )}
                  {!userIsCustomer && (
                    <MenuItem
                      onClick={(event) => handleMenuItemClick('cancelDeliverables', event)}
                      disabled={isLoadingApi || !quickActionStatusDisabled.cancellation}
                    >
                      <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <Box sx={{ mr: 1 }}>{t<string>('Cancellation')}</Box>
                        {quickActionsAffectation.cancellation !== 0 && (
                          <Chip
                            size="small"
                            color="primary"
                            label={quickActionsAffectation.cancellation}
                            sx={{ fontSize: '1rem', fontWeight: 500 }}
                          />
                        )}
                      </Box>
                    </MenuItem>
                  )}
                  {(userIsAdmin || userIsBm || userIsTl || userIsTlPlus) && (
                    <MenuItem
                      onClick={(event) => handleMenuItemClick('cancelValidation', event)}
                      disabled={isLoadingApi || quickActionStatusDisabled.cancelvalidation}
                    >
                      <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <Box sx={{ mr: 1 }}>{t<string>('Cancel_validation')}</Box>
                        {quickActionsAffectation.cancelvalidation !== 0 && (
                          <Chip
                            size="small"
                            color="primary"
                            label={quickActionsAffectation.cancelvalidation}
                            sx={{ fontSize: '1rem', fontWeight: 500 }}
                          />
                        )}
                      </Box>
                    </MenuItem>
                  )}
                  {!userIsCustomer && (
                    <MenuItem
                      onClick={(event) => handleMenuItemClick('deleteDeliverables', event)}
                      disabled={isLoadingApi || quickActionStatusDisabled.delete}
                    >
                      <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <Box sx={{ mr: 1 }}>{t<string>('Delete_deliverables')}</Box>
                        {quickActionsAffectation.delete !== 0 && (
                          <Chip
                            size="small"
                            color="primary"
                            label={quickActionsAffectation.delete}
                            sx={{ fontSize: '1rem', fontWeight: 500 }}
                          />
                        )}
                      </Box>
                    </MenuItem>
                  )}
                  {(userIsCustomer || (!clientConnection && canManagerRate)) && !state.selectedAllDeliverables && (
                    <MenuItem
                      onClick={(event) => handleMenuItemClick('deliverablesAssessment', event)}
                      disabled={isLoadingApi || !canDeliverablesBeEvaluated || quickActions.acceptation}
                    >
                      Deliverables assessment
                    </MenuItem>
                  )}
                </MenuList>
              </Menu>
            </Box>
            <RatingPopup
              open={isRatingPopupOpen}
              onClose={handleCloseRatingPopup}
              onSubmit={handleSubmitRatingPopup}
              setQuickActions={setQuickActions}
            />
          </BottomNavigation>
        )}
    </>
  );
};

export default DeliverableQuickActions;
