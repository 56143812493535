import { Add, FilterAltOff, Search } from '@mui/icons-material';
import { Badge, Box, Button, IconButton, TextField, Tooltip } from '@mui/material';
import useManagementRights from 'hooks/rights/useManagementRights';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useHistory } from 'react-router-dom';
import { clearSelectedFilterAction } from 'store/actions/filterActions';
import { useSelector } from 'store/hooks';
import { useAppState } from 'store/Provider';
import { OrdersTabState } from 'types/enums/OrdersTabState';
import { OrdersTabParentState } from 'types/enums/OrdersTabParentState';
import useTextSearch from '../../../../hooks/useTextSearch';
import { openDrawerAction } from '../../../../store/actions/drawerActions';
import { selectFilterSelectedSize } from '../../../../store/selectors/filterSelectors';
import './ManagementHeader.scss';

const ManagementHeader = () => {
  const [t] = useTranslation();
  const { dispatch } = useAppState();
  const { app, management, filter } = useSelector((state) => state);
  const history = useHistory();
  const filterSelectedSize = useSelector(selectFilterSelectedSize);
  const managementRight = useManagementRights();
  const { textSearch, setTextSearch, debouncedOnChange } = useTextSearch();

  return (
    <Box sx={{ display: 'flex', mb: 4, alignItems: { md: 'center' }, flexDirection: { xs: 'column', md: 'row' } }}>
      {management.pageHandler.currentTab === OrdersTabState.DRAFTED && managementRight.canCreateOrder && (
        <Button
          variant="contained"
          LinkComponent={Link}
          startIcon={<Add />}
          onClick={() => history.push(`/${app.customer?.slug}/catalogs?create_quote=true`)}
          sx={{ mb: { xs: 3, md: 0 } }}
        >
          {t('create_new_quote')}
        </Button>
      )}
      {management.pageHandler.currentTab === OrdersTabState.MATERIAL_DRAFT && managementRight.canCreateOrder && (
        <Button
          variant="contained"
          LinkComponent={Link}
          startIcon={<Add />}
          onClick={() => history.push(`/${app.customer?.slug}/orders/production/create_order`)}
          sx={{ mb: { xs: 3, md: 0 } }}
        >
          {t('Create_new_order')}
        </Button>
      )}
      {filter.items.length > 0 && (
        <Box sx={{ ml: { md: 'auto' }, display: 'flex', alignItems: 'center' }}>
          {filterSelectedSize > 0 && (
            <Box>
              <Tooltip title={t('Clear_filters') as string}>
                <IconButton
                  onClick={() => {
                    dispatch(clearSelectedFilterAction());
                  }}
                  color="primary"
                  sx={{ mr: 2 }}
                >
                  <FilterAltOff />
                </IconButton>
              </Tooltip>
            </Box>
          )}
          <Tooltip title={t('search_by_name_or_ref') as string}>
            <TextField
              placeholder={t('Search')}
              variant="standard"
              InputProps={{
                endAdornment: <Search sx={{ color: 'neutral.main' }} />,
              }}
              value={textSearch}
              onChange={(e) => {
                debouncedOnChange();
                setTextSearch(e.target.value);
              }}
              sx={{ mr: 2, minWidth: { md: 260 } }}
            />
          </Tooltip>
          <Badge badgeContent={filterSelectedSize} color="primary">
            <Button
              onClick={() => {
                dispatch(openDrawerAction({ name: 'drawerFilters' }));
              }}
              variant="contained"
            >
              {t('Filters')}
            </Button>
          </Badge>
        </Box>
      )}
    </Box>
  );
};

export default ManagementHeader;
