/* eslint-disable max-len */
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Box, Button, Card, CardContent, Chip, Divider, Grid, Typography } from '@mui/material';
import { CalendarIcon } from 'components/CustomIcons';
import { ProgressBar } from 'components/index';
import { formatDateToddMMYYY } from 'helpers/format';
import useDashboardRights from 'hooks/rights/useDashboardRights';
// eslint-disable-next-line import/order
/* eslint-disable array-callback-return */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import { useTranslation } from 'react-i18next';
import Order from 'types/entities/Order';
import './OrderCard.scss';
import { useSelector } from '../../../store/hooks';

interface Props {
  order: Order;
}

const OrderCard: React.FC<Props> = ({ order }) => {
  const { canSeePrice } = useDashboardRights();
  const [expanded, setExpanded] = React.useState(false);
  const [t] = useTranslation();
  const devises = useSelector((state) => state.app.devises);

  const getTimeLeft = () => {
    const date1 = new Date();
    const date2 = new Date(order.end_date);
    const diffInMs = date2.getTime() - date1.getTime();

    if (diffInMs < 0) {
      const diffInDays = Math.floor(Math.abs(diffInMs) / (1000 * 60 * 60 * 24));
      if (diffInDays < 30) {
        return { number: diffInDays, unity: 'day(s) overdue' };
      }
      if (diffInDays < 365) {
        const diffInMonths = Math.floor(diffInDays / 30);
        return { number: diffInMonths, unity: 'month(s) overdue' };
      }
      const diffInYears = Math.floor(diffInDays / 365);
      return { number: diffInYears, unity: 'year(s) overdue' };
    }
    if (diffInMs === 0) {
      return { number: 0, unity: 'today' };
    }
    const diffInDays = Math.floor(diffInMs / (1000 * 60 * 60 * 24));
    if (diffInDays < 30) {
      return { number: diffInDays, unity: 'day(s) left' };
    }
    if (diffInDays < 365) {
      const diffInMonths = Math.floor(diffInDays / 30);
      return { number: diffInMonths, unity: 'month(s) left' };
    }
    const diffInYears = Math.floor(diffInDays / 365);
    return { number: diffInYears, unity: 'year(s) left' };
  };

  const timeLeft = getTimeLeft();

  const handleClick = () => {
    setExpanded(!expanded);
  };

  const currentDevise = devises?.find((devise) => order?.devise_name[0] === devise.name);

  return (
    <Grid item xs={12} lg={4}>
      <Card>
        <CardContent>
          <Box sx={{ mb: 2 }}>
            <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
              <CalendarIcon fontSize="small" sx={{ color: 'neutral.main' }} />
              <Typography sx={{ color: 'neutral.main', ml: 1 }}>{formatDateToddMMYYY(order.created_at)}</Typography>
            </Box>
            <Typography variant="h4" component="div">
              {order?.name_spec}
            </Typography>
          </Box>
          <Box sx={{ mb: 3 }}>
            <Box sx={{ display: 'flex' }}>
              <Typography sx={{ color: 'text.secondary' }}>Submitted</Typography>
              <Typography sx={{ ml: 'auto', color: 'text.secondary' }}>
                {order?.percentage_of_submitted_deliverables}%
              </Typography>
            </Box>
            <ProgressBar value={order?.percentage_of_submitted_deliverables} />
          </Box>
          <Box sx={{ mb: 3 }}>
            <Box sx={{ display: 'flex' }}>
              <Typography sx={{ color: 'text.secondary' }}>Validated</Typography>
              <Typography sx={{ ml: 'auto', color: 'text.secondary' }}>
                {order?.percentage_of_realised_deliverables}%
              </Typography>
            </Box>
            <ProgressBar value={order?.percentage_of_realised_deliverables} />
          </Box>
          <Box sx={{ mb: 3 }}>
            <Box sx={{ display: 'flex' }}>
              <Typography sx={{ color: 'text.secondary' }}>Accepted</Typography>
              <Typography sx={{ ml: 'auto', color: 'text.secondary' }}>
                {order?.percentage_of_accepted_deliverables}%
              </Typography>
            </Box>
            <ProgressBar value={order?.percentage_of_accepted_deliverables} />
          </Box>
          <Chip
            label={`${Math.abs(timeLeft.number)} ${timeLeft.unity} `}
            color={timeLeft.unity.includes('overdue') ? 'error' : 'primary'}
            sx={{ fontWeight: 600, display: 'flex', mb: 3 }}
          />
          {expanded && (
            <Grid container>
              <Grid item xs={12} lg={6}>
                <Box sx={{ mb: 2 }}>
                  <Typography variant="body2" sx={{ ml: 'auto', color: 'text.secondary' }} noWrap>
                    Ref specification
                  </Typography>
                  <Typography sx={{ fontWeight: 600 }}>{order.ref_spec}</Typography>
                </Box>
              </Grid>
              <Grid item xs={12} lg={6}>
                <Box sx={{ mb: 2 }}>
                  <Typography variant="body2" sx={{ ml: 'auto', color: 'text.secondary' }} noWrap>
                    Ref workpackage
                  </Typography>
                  <Typography sx={{ fontWeight: 600 }}>{order.ref_work}</Typography>
                </Box>
              </Grid>
              <Grid item xs={12} lg={6}>
                <Box sx={{ mb: 2 }}>
                  <Typography variant="body2" sx={{ ml: 'auto', color: 'text.secondary' }} noWrap>
                    Start date
                  </Typography>
                  <Typography sx={{ fontWeight: 600 }}>{formatDateToddMMYYY(order.start_date)}</Typography>
                </Box>
              </Grid>
              <Grid item xs={12} lg={6}>
                <Box sx={{ mb: 2 }}>
                  <Typography variant="body2" sx={{ ml: 'auto', color: 'text.secondary' }} noWrap>
                    End date
                  </Typography>
                  <Typography sx={{ fontWeight: 600 }}>{formatDateToddMMYYY(order.end_date)}</Typography>
                </Box>
              </Grid>
              <Grid item xs={12} lg={6}>
                <Box sx={{ mb: 2 }}>
                  <Typography variant="body2" sx={{ ml: 'auto', color: 'text.secondary' }} noWrap>
                    Workunits number
                  </Typography>
                  <Typography sx={{ fontWeight: 600 }}>{order?.nb_order_workunits || 0}</Typography>
                </Box>
              </Grid>
              {canSeePrice && (
                <Grid item xs={12} lg={6}>
                  <Box sx={{ mb: 2 }}>
                    <Typography variant="body2" sx={{ ml: 'auto', color: 'text.secondary' }} noWrap>
                      Total amount
                    </Typography>
                    <Typography sx={{ fontWeight: 600 }}>
                      {order?.final_amount_price.toFixed(2)}
                      {currentDevise?.symbol && currentDevise?.symbol !== 'E'
                        ? currentDevise?.symbol?.toLowerCase()
                        : '€'}
                    </Typography>
                  </Box>
                </Grid>
              )}
            </Grid>
          )}
          <Divider sx={{ mb: 3, borderColor: 'neutral.main' }} />
          <Button
            endIcon={expanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
            onClick={handleClick}
            fullWidth
            variant="outlined"
          >
            {expanded ? t('hide_details') : t('see_details')}
          </Button>
        </CardContent>
      </Card>
    </Grid>
  );
};
export default OrderCard;
