import useManagementRights from 'hooks/rights/useManagementRights';
import React from 'react';
import { useAppState } from 'store/Provider';
import { OrdersTabState } from 'types/enums/OrdersTabState';
import { OrdersTabParentState } from 'types/enums/OrdersTabParentState';
import ManagementParentTabs from './ManagementTabs/ManagementParentTabs';
import ManagementTabs from './ManagementTabs/ManagementTabs';
import MaterialTabs from './ManagementTabs/MaterialTabs';
import { TabContext, TabPanel } from '@mui/lab';
import useManagementHelper from '../services/useManagementHelper';

type ManagementHeadBandProps = {
  currentToAssignCount: number;
};

const ManagementHeadband = (props: ManagementHeadBandProps) => {
  const { currentToAssignCount } = props;
  const { state } = useAppState();
  const { canShowTabsOrder } = useManagementRights();
  const { isPathForProduction } = useManagementHelper();

  return canShowTabsOrder ? (
    <>
      {isPathForProduction() ? (
        <TabContext value={state.management.pageHandler.parentTab}>
          <ManagementParentTabs />
          <TabPanel value={OrdersTabParentState.CATALOG}>
            <ManagementTabs currentToAssignCount={currentToAssignCount} />
          </TabPanel>
          <TabPanel value={OrdersTabParentState.MATERIAL}>
            <MaterialTabs currentToAssignCount={currentToAssignCount} />
          </TabPanel>
        </TabContext>
      ) : (
        <ManagementTabs currentToAssignCount={currentToAssignCount} />
      )}
    </>
  ) : (
    <></>
  );
};

export default ManagementHeadband;
