import React, { useState, useRef, useEffect, SyntheticEvent, useCallback } from 'react';
import {
  AutocompleteChangeReason,
  AutocompleteChangeDetails,
  createFilterOptions,
  AutocompleteRenderInputParams,
} from '@mui/material/Autocomplete';
import {
  Autocomplete as MUIAutocomplete,
  TextField,
  Grid,
  Checkbox,
  FormControlLabel,
  Typography,
  Box,
  Tooltip,
  AvatarGroup,
  Popover,
  Button,
  IconButton,
  Snackbar,
  Alert,
} from '@mui/material';
import { CustomAvatar } from 'components/index';
import CloseIcon from '@mui/icons-material/Close';
import CheckIcon from '@mui/icons-material/Check';
import AddIcon from '@mui/icons-material/Add';
import { setSnackbarAction } from 'store/actions/snackbarActions';
import { useAppState } from 'store/Provider';
import { useSelector } from 'store/hooks';
import ScopeApiService from 'api/ScopeApiService';
import Scope from 'types/entities/Scope';
import { noop } from 'lodash'; // Import noop from lodash

type MultiSelectAutocompleteProps = {
  items: Scope[];
  onChange: (scopes: Scope[]) => void;
  value: Scope[];
  sx?: any;
  selectAllLabel: string;
  variant?: string;
  onScopeAdded: (newScope: Scope) => void;
  onScopeArchived: (updatedScope: Scope) => void;
  availableScopes?: Scope[];
  onScopeDeletionAttempt?: () => void;
  maximumVisible: number;
};

const MultiSelectAutocomplete = (props: MultiSelectAutocompleteProps) => {
  const {
    items,
    onChange,
    value,
    sx,
    selectAllLabel,
    variant,
    onScopeAdded,
    onScopeArchived,
    availableScopes,
    onScopeDeletionAttempt = noop,
    maximumVisible,
  } = props;
  const inputRef = useRef<HTMLInputElement>(null);
  const { dispatch } = useAppState();
  const maxVisible = maximumVisible; // Adjust based on your max setting
  const [dynamicItems, setDynamicItems] = useState<Scope[]>(items);
  const [selectedOptions, setSelectedOptions] = useState<Scope[]>(value);
  const [popoverOpen, setPopoverOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [key, setKey] = useState<number>(0); // Key to force re-render
  const [inputValue, setInputValue] = useState('');
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [addPopoverOpen, setAddPopoverOpen] = useState(false);
  const customer = useSelector((state) => state.app.customer);

  useEffect(() => {
    // console.log('useEffect triggered for items');
    // console.log('Previous dynamicItems:', dynamicItems);
    setDynamicItems(items);
    // console.log('Items updated:', items);
  }, [items]);

  useEffect(() => {
    // console.log('useEffect triggered for value');
    // console.log('Previous selectedOptions:', selectedOptions);
    setSelectedOptions(value);
    // console.log('Value updated:', value);
  }, [value]);

  const handleAddClick = useCallback((event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
    setAddPopoverOpen(true);
    if (inputRef.current) {
      inputRef.current.value = '';
      setInputValue('');
    }
  }, []);

  const handleAddClose = useCallback(() => {
    if (inputRef.current) {
      inputRef.current.value = '';
      setInputValue('');
    }
    setAddPopoverOpen(false);
  }, []);

  useEffect(() => {
    // Whenever the popover is open, focus the input
    if (addPopoverOpen && inputRef.current) {
      inputRef.current.focus();
    }
  }, [addPopoverOpen]);

  const filterOptions = createFilterOptions<Scope>({
    matchFrom: 'start',
    stringify: (option: Scope) => option.name,
  });

  const handleOptionChange = useCallback(
    (event: SyntheticEvent<Element, Event>, newValue: Scope[], reason: AutocompleteChangeReason) => {
      const uncheckedScope = selectedOptions.find((scope) => !newValue.some((newScope) => newScope.id === scope.id));
      if (uncheckedScope) {
        if (availableScopes?.some((scope) => scope.id === uncheckedScope.id)) {
          //  console.log(`Cannot uncheck scope "${uncheckedScope.name}" because it is in availableScopes.`);
          onScopeDeletionAttempt();
          return;
        }
      }
      setSelectedOptions(newValue);
      onChange(newValue);
    },
    [selectedOptions, availableScopes, onChange, onScopeDeletionAttempt]
  );

  const optionRenderer = (props: any, option: Scope, state: { selected: boolean }) => (
    <Grid container key={option.name}>
      <Grid item xs={12}>
        <FormControlLabel control={<Checkbox {...props} checked={state.selected} />} label={option.name} />
      </Grid>
    </Grid>
  );

  const getOptionLabel = (option: Scope): string => option.name;

  const isOptionEqualToValue = (option: Scope, val: Scope): boolean => option.id === val.id;

  const visibleItems = selectedOptions.slice(0, maxVisible);
  const overflowItems = selectedOptions.slice(maxVisible); // This should be the count of items in overflow

  const handleAvatarGroupClick = (event: any) => {
    if (selectedOptions.length > maxVisible) {
      // Assuming "+2" is clicked if there are more than 3 options
      setPopoverOpen(true);
      setAnchorEl(event.currentTarget);
    }
  };

  const handleAddNewScope = async () => {
    const newLabel = inputValue.trim();
    if (!newLabel || dynamicItems.some((item) => item.name.toLowerCase() === newLabel.toLowerCase())) {
      dispatch(setSnackbarAction({ message: `Scope already exists.`, open: true, severity: 'error' }));
      return;
    }

    const maxId = dynamicItems.reduce((max, item) => (item.id > max ? item.id : max), 0) + 1;

    const newScope: Scope = {
      id: maxId,
      name: newLabel,
      created_at: new Date().toISOString(),
      customer_id: customer?.id as number,
      is_archived: false,
      updated_at: new Date().toISOString(),
    };

    setIsLoading(true);
    try {
      const response = await ScopeApiService.postScope(newScope);
      if (response?.status === 200 || response?.status === 201) {
        // Successfully added the scope, update state
        const createdScope = response.data.scope;
        setDynamicItems((prevItems) => [...prevItems, createdScope]);
        setInputValue('');
        setAddPopoverOpen(false);
        dispatch(
          setSnackbarAction({
            message: `Scope added successfully.`,
            open: true,
            severity: 'success',
          })
        );
        onScopeAdded(createdScope);
      } else {
        // Handle responses other than success
        throw new Error(`Failed to add scope, server responded with status: ${response?.status}`);
      }
    } catch (error: any) {
      // Handle errors from the API or network issues
      dispatch(
        setSnackbarAction({
          message: error.response?.data?.message || `Error adding scope: ${error.message}`,
          open: true,
          severity: 'error',
        })
      );
    } finally {
      setIsLoading(false);
    }
  };

  const handleClosePopover = () => {
    setPopoverOpen(false);
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
    // console.log('event.key', event.key);
    // console.log('inputValue', !inputValue);
    if (event.key === 'Backspace' && !inputValue) {
      // console.log('Backspace pressed on empty input');
      event.preventDefault();
    }
  };

  const handleAutocompleteKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
    //  console.log('event.key', event.key);
    if (event.key === 'Backspace' || event.key === 'Delete' || event.key === 'ArrowLeft') {
      event.stopPropagation();
    }
  };

  const handleInputBlur = () => {
    if (addPopoverOpen) {
      inputRef.current?.focus();
    }
  };

  const renderInput = (params: AutocompleteRenderInputParams): JSX.Element => (
    <Box position="relative" width="100%">
      <TextField
        {...params}
        label={selectedOptions.length > 0 ? `${selectedOptions.length} selected` : selectAllLabel}
        variant={variant as any}
        onKeyDown={handleAutocompleteKeyDown}
        InputProps={{
          ...params.InputProps,
          endAdornment: (
            <div>
              {params.InputProps.endAdornment}
              <Box display="flex" justifyContent="flex-end">
                {' '}
                <IconButton
                  onClick={handleAddClick}
                  color="primary"
                  size="small"
                  sx={{
                    position: 'absolute',
                    right: 30, // Align to the right edge of the TextField
                    top: '50%', // Center vertically
                    transform: 'translateY(-50%)', // Ensure vertical centering
                    border: '1px solid',
                    backgroundColor: 'transparent', // Use theme color
                    borderColor: 'primary.main', // Color of the border
                    color: 'primary.main',
                    '&:hover': {
                      backgroundColor: 'primary.light', // Light background on hover for contrast
                      color: 'primary.main',
                    },
                    width: 30, // Specific width for square shape
                    height: 30, // Specific height for square shape
                    borderRadius: '0', // Square edges
                  }}
                >
                  <AddIcon />
                </IconButton>
                <Popover
                  open={addPopoverOpen}
                  anchorEl={anchorEl}
                  onClose={handleAddClose}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                >
                  <Box p={2} display="flex" alignItems="center" justifyContent="space-between">
                    <TextField
                      autoFocus
                      placeholder="create your own scope"
                      value={inputValue}
                      onChange={(e) => setInputValue(e.target.value)}
                      onKeyPress={(e) => e.key === 'Enter' && handleAddNewScope()}
                      onKeyDown={handleKeyDown}
                      onBlur={handleInputBlur}
                      fullWidth
                      inputRef={inputRef}
                    />
                    <IconButton onClick={handleAddClose} color="primary" sx={{ color: 'red' }}>
                      <CloseIcon />
                    </IconButton>
                    <IconButton
                      onClick={handleAddNewScope}
                      color="primary"
                      sx={{ color: 'green' }}
                      disabled={inputValue.trim().length < 3 || isLoading}
                    >
                      <CheckIcon />
                    </IconButton>
                  </Box>
                </Popover>
              </Box>
            </div>
          ),
        }}
      />
    </Box>
  );

  const handleDelete = (scopeId: number) => {
    let shouldArchiveScope = true;
    setSelectedOptions((prevOptions) => {
      const scopeToDelete = prevOptions.find((scope) => scope.id === scopeId);
      // console.log('scopeToDelete:', scopeToDelete);

      if (scopeToDelete) {
        if (availableScopes?.some((scope) => scope.id === scopeToDelete.id)) {
          onScopeDeletionAttempt();
          shouldArchiveScope = false;
          return prevOptions;
        }
        // Return the updated options, filtering out the deleted scope
        return prevOptions.filter((scope) => scope.id !== scopeId);
      }

      return prevOptions;
    });

    // Use useEffect or a separate function to handle the side effect
    if (shouldArchiveScope) {
      archiveScope(scopeId);
    }
  };

  const archiveScope = (scopeId: number) => {
    // console.log('scopeid:', scopeId);
    const scopeToArchive = selectedOptions.find((scope) => scope.id === scopeId);

    if (scopeToArchive) {
      const scope: Scope = {
        id: scopeToArchive.id,
        name: scopeToArchive.name,
        created_at: '', // Placeholder or fetch actual value if available
        customer_id: customer?.id as number, // Assuming customer id is needed
        is_archived: true,
        updated_at: new Date().toISOString(),
      };

      // Call the onScopeArchived with the Scope object
      onScopeArchived(scope);
    }
  };

  useEffect(() => {
    const overflowItems = selectedOptions.length - maxVisible;
    if (overflowItems <= 0 && popoverOpen) {
      setPopoverOpen(false);
    }
    // console.log('Overflow items:', overflowItems);
  }, [selectedOptions, popoverOpen, maxVisible]);

  useEffect(() => {
    onChange(selectedOptions);
  }, [selectedOptions, onChange]);

  const avatarsToRender: any = [];

  visibleItems.forEach((option, index) => {
    avatarsToRender.push(
      <CustomAvatar
        displayAvatar={false}
        sx={{ marginRight: '10px', padding: '8px 1px' }}
        key={option.id}
        name={option.name}
        onClose={() => handleDelete(option.id)}
      />
    );

    if (index === maxVisible - 1 && selectedOptions.length > maxVisible) {
      const remainingCount = selectedOptions.length - maxVisible;
      avatarsToRender.push(
        <Box
          component="span"
          onClick={handleAvatarGroupClick}
          sx={{
            cursor: 'pointer',
            backgroundColor: '#808080',
            borderRadius: '50%',
            padding: '2px',
            display: 'inline-flex',
            color: 'white',
            width: '24px',
            height: '24px',
            justifyContent: 'center',
            alignItems: 'center',
            marginTop: '12px',
            fontSize: '12px', // Smaller font size
          }}
          key="overflow"
        >
          +{remainingCount}
        </Box>
      );
    }
  });

  return (
    <>
      <MUIAutocomplete
        disableCloseOnSelect
        key={key}
        sx={{
          width: '350px',
          maxHeight: '150px',
          '& .Mui-focused, & .MuiAutocomplete-inputFocused': {
            backgroundColor: 'transparent',
            outline: 'none',
          },
          ...sx,
        }}
        multiple
        size="small"
        options={dynamicItems}
        value={selectedOptions}
        getOptionLabel={getOptionLabel}
        isOptionEqualToValue={isOptionEqualToValue}
        filterOptions={filterOptions}
        onChange={handleOptionChange}
        renderOption={optionRenderer}
        renderInput={renderInput}
        renderTags={() => null} // Do not render tags for selected items
        ListboxProps={{
          style: {
            maxHeight: '320px', // Maximum height for the options list, after which a scrollbar appears
            overflow: 'auto', // Ensures scrollbar appears when content exceeds the container
          },
        }}
        clearIcon={null}
      />
      <Box sx={{ display: 'flex', justifyContent: 'flex-start' }}>
        <Tooltip placement="bottom-start" title="">
          <AvatarGroup
            max={maxVisible + 1}
            sx={{ '& .MuiAvatar-root': { width: 30, height: 30, marginRight: 0.5 } }}
            onClick={handleAvatarGroupClick}
          >
            {avatarsToRender}
          </AvatarGroup>
        </Tooltip>
        <Popover
          open={popoverOpen}
          anchorEl={anchorEl}
          onClose={handleClosePopover}
          PaperProps={{
            style: {
              minWidth: 100,
              maxWidth: 300,
              minHeight: 100, // Set a minimum height for the popover
              boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.25)',
              backgroundColor: 'transparent',
            },
          }}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'flex-start',
              maxHeight: 200,
              minWidth: 100,
              maxWidth: 300,
              overflowY: 'auto',
              p: 2,
            }}
          >
            {overflowItems.map((option) => (
              <CustomAvatar
                displayAvatar={false}
                key={option.id}
                name={option.name}
                onClose={() => handleDelete(option.id)}
                sx={{ marginBottom: 1 }}
              />
            ))}
          </Box>
        </Popover>
      </Box>
    </>
  );
};

export default MultiSelectAutocomplete;
