import { AssignmentInd, AssignmentOutlined } from '@mui/icons-material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import SettingsBackupRestoreIcon from '@mui/icons-material/SettingsBackupRestore';
import { IconButton, ListItemIcon, ListItemText } from '@mui/material';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Loader from 'components/Loader';
import {
  BookCloseIcon,
  BookOpenIcon,
  DeleteIcon,
  DownloadIcon,
  DuplicateIcon,
  EditIcon,
  WorkunitsIcon,
} from 'components/CustomIcons';
import useManagementService from 'features/Management/services/useManagementService';
import useManagementRights from 'hooks/rights/useManagementRights';
import { some } from 'lodash';
import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'store/hooks';
import Order from 'types/entities/Order';
import './OrderMenu.scss';
import { OrderType } from 'types/enums/OrderType';

interface Props {
  order: Order;
}

const OrderMenu: React.FC<Props> = ({ order }) => {
  const loadingState = useSelector((state) => state.loadings);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const {
    archiveOrder,
    openOrder,
    downloadSelectedProductionExcel,
    restoreOrder,
    navigateToSummary,
    navigateToAffectation,
    openManagementDuplicationDialog,
    openManagementCloseDialog,
    openManagementOpenDialog,
    navigateToOrderCreationWorkunits,
    openEditOrderDrawer,
    openEditTimeMaterialDialog,
  } = useManagementService();
  const {
    canCreateWorkunitOrder,
    canShowSummaryOrder,
    canAffectationOrder,
    canEditOrder,
    canDuplicateOrder,
    canDownloadOrder,
    canCloseOrder,
    canOpenOrder,
    canArchiveOrder,
    canRestoreOrder,
  } = useManagementRights(order);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const [t] = useTranslation();
  const handleOpenEditDrawer = useCallback(() => {
    if (order.order_type_id === OrderType.TIMEANDMATERIAL) {
      openEditTimeMaterialDialog(order, 'edit');
      handleClose();
      //  console.log(`openEditTimeMaterialDialog called`);
    } else {
      openEditOrderDrawer(order);
      handleClose();
    }
  }, [order, openEditOrderDrawer, openEditTimeMaterialDialog]);
  const menuItems = useMemo(
    () => [
      {
        action: () => navigateToOrderCreationWorkunits(order.id, order.catalog_id),
        permission: canCreateWorkunitOrder,
        label: 'Workunits',
        icon: <WorkunitsIcon sx={{ color: 'text.primary' }} fontSize="small" />,
      },
      {
        action: navigateToSummary,
        permission: canShowSummaryOrder,
        label: 'Summary',
        icon: <AssignmentOutlined sx={{ color: 'text.primary' }} />,
      },
      {
        action: navigateToAffectation,
        permission: canAffectationOrder,
        label: 'Affectation',
        icon: <AssignmentInd sx={{ color: 'text.primary' }} />,
      },
      {
        action: handleOpenEditDrawer,
        permission: canEditOrder,
        label: 'Edit',
        icon: <EditIcon sx={{ color: 'text.primary' }} fontSize="small" />,
      },
      {
        action: openManagementDuplicationDialog,
        permission: canDuplicateOrder,
        label: 'Duplicate',
        icon: <DuplicateIcon sx={{ color: 'text.primary' }} fontSize="small" />,
      },
      {
        action: downloadSelectedProductionExcel,
        permission: canDownloadOrder,
        label: 'Excel',
        icon: <DownloadIcon sx={{ color: 'text.primary' }} fontSize="small" />,
      },
      {
        action: openManagementCloseDialog,
        permission: canCloseOrder,
        label: 'Close',
        icon: <BookCloseIcon sx={{ color: 'text.primary' }} fontSize="small" />,
      },
      {
        action: openManagementOpenDialog,
        permission: canOpenOrder,
        label: 'Reopen',
        icon: <BookOpenIcon sx={{ color: 'text.primary' }} fontSize="small" />,
      },
      {
        action: archiveOrder,
        permission: canArchiveOrder,
        label: 'Delete',
        color: 'error.main',
        icon: <DeleteIcon sx={{ color: 'error.main' }} fontSize="small" />,
        isLoading: (loadingState?.[`archive_${order?.id}`] ?? 0) !== 0,
      },
      {
        action: restoreOrder,
        permission: canRestoreOrder,
        label: 'Restore',
        icon: <SettingsBackupRestoreIcon sx={{ color: 'text.primary' }} fontSize="small" />,
        isLoading: (loadingState?.[`restore_${order?.id}`] ?? 0) !== 0,
      },
    ],
    [
      canCreateWorkunitOrder,
      navigateToSummary,
      canShowSummaryOrder,
      navigateToAffectation,
      canAffectationOrder,
      handleOpenEditDrawer,
      canEditOrder,
      openManagementDuplicationDialog,
      canDuplicateOrder,
      downloadSelectedProductionExcel,
      canDownloadOrder,
      openManagementCloseDialog,
      canCloseOrder,
      openManagementOpenDialog,
      canOpenOrder,
      archiveOrder,
      canArchiveOrder,
      loadingState,
      order.id,
      order.catalog_id,
      restoreOrder,
      canRestoreOrder,
      navigateToOrderCreationWorkunits,
    ]
  );

  return some({
    canCreateWorkunitOrder,
    canShowSummaryOrder,
    canAffectationOrder,
    canEditOrder,
    canDuplicateOrder,
    canDownloadOrder,
    canCloseOrder,
    canOpenOrder,
    canArchiveOrder,
    canRestoreOrder,
  }) ? (
    <>
      <IconButton
        aria-label="more"
        aria-controls="long-menu"
        aria-haspopup="true"
        onClick={handleClick}
        size="large"
        data-testid={`menu_order_${order.id}`}
      >
        <MoreVertIcon />
      </IconButton>
      <Menu id="long-menu" anchorEl={anchorEl} keepMounted open={open} onClose={handleClose}>
        {menuItems
          .filter((item) => item.permission)
          .map((item) => (
            <MenuItem
              key={item.label}
              onClick={() => item.action(order.id)}
              disabled={item.isLoading}
              sx={{ color: item.color }}
            >
              {item.isLoading ? (
                <Loader size={10} />
              ) : (
                <>
                  <ListItemIcon>{item.icon}</ListItemIcon>
                  <ListItemText>{t(item.label)}</ListItemText>
                </>
              )}
            </MenuItem>
          ))}
      </Menu>
    </>
  ) : (
    <></>
  );
};
export default OrderMenu;
