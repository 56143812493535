import { Close, CompassCalibrationOutlined, ExpandMore } from '@mui/icons-material';
import RefreshIcon from '@mui/icons-material/Refresh';
import { DesktopDatePicker, LocalizationProvider } from '@mui/lab';
import MomentUtils from '@mui/lab/AdapterMoment';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Autocomplete,
  Box,
  Button,
  Checkbox,
  Chip,
  Drawer,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  TextField,
  Typography,
} from '@mui/material';
import Select from '@mui/material/Select';
import InputDate from 'components/InputDate';
import InputDateRange from 'components/InputDateRange';
import { forEach } from 'lodash';
import moment, { Moment } from 'moment';
import React, { useEffect, useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { closeDrawerAction } from 'store/actions/drawerActions';
import { clearSelectedFilterAction, selectFilterAction, resetAllFilterAction } from 'store/actions/filterActions';
import { useSelector } from 'store/hooks';
import { useAppState } from 'store/Provider';
import { FilterItemOption, MultipleFilterType } from 'types/models/FilterItem';
import { SelectedFiltersList } from 'types/models/SelectedFiltersList';

type inputRenderer = {
  [key: string]: JSX.Element;
};

type Props = {
  baseUrl?: {
    pathname: string;
    value: string;
  };
};

type InputDateRangeHandle = {
  clear: () => void;
};

type ActiveFilter = [string, string[]];

const DrawerFilters: React.FC<Props> = ({ baseUrl }) => {
  const { dispatch } = useAppState();
  const { drawer, filter } = useSelector((state) => state);
  const { search } = useLocation();
  const [t] = useTranslation();
  const isOpen = drawer.name === 'drawerFilters';
  const onClose = () => dispatch(closeDrawerAction());
  const [expanded, setExpanded] = useState<string | false>('dateFilter');
  const dateRangeRef = useRef<InputDateRangeHandle>(null);
  const [inputKey, setInputKey] = useState(0);
  const [activeFilters, setActiveFilters] = useState<ActiveFilter[]>([]);
  const userLocale = navigator.language || 'fr-FR';
  const dateFormat = userLocale !== 'en-US' ? 'DD/MM/YYYY' : 'MM/DD/YYYY';

  useEffect(() => {
    if (isOpen) {
      setExpanded('dateFilter');
    }
  }, [isOpen]);

  useEffect(() => {
    const query = new URLSearchParams(search);
    if (query.toString() && filter.items.length) {
      const filters: SelectedFiltersList = {};
      filter.items.forEach((filterItem) => {
        if (filterItem.rangeDate) {
          const queryFieldStart = query.get(`${filterItem.field}_start`);
          const queryFieldEnd = query.get(`${filterItem.field}_end`);
          if (queryFieldStart) {
            filters[`${filterItem.field}_start`] = [queryFieldStart];
          }
          if (queryFieldEnd) {
            filters[`${filterItem.field}_end`] = [queryFieldEnd];
          }
        } else {
          const queryField = query.get(filterItem.field);
          if (queryField) {
            switch (filterItem.field) {
              case 'mission_advancement_id':
              case 'scopes':
              case 'label':
                filters[filterItem.field] = queryField.split(',');
                break;
              default:
                filters[filterItem.field] = [queryField];
            }
          }
        }
      });
      const queryTextSearch = query.get(`text_search`);
      if (queryTextSearch) {
        filters.text_search = [queryTextSearch];
      }

      dispatch(selectFilterAction(filters));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filter.items, search]);

  useEffect(() => {
    if (filter.items.length) {
      const url = new URL(window.location.href);
      const searchParams = new URLSearchParams(url.searchParams.toString());
      let hasChanged = false;
      searchParams.forEach((value, key) => {
        if (key !== 'status') {
          url.searchParams.delete(key);
          hasChanged = true;
        }
      });
      forEach(filter.selected, (values, key) => {
        if (values.length > 0 && values[0]) {
          url.searchParams.set(key, values.join(','));
          hasChanged = true;
        }
      });
      if (hasChanged && !url.toString().includes('deliverables') && !url.toString().includes('deliverablesheet')) {
        // Push the URL only if it has changed
        window.history.pushState(null, '', url.toString());
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filter.selected]);

  const handleFilterChange = (event: any, field: string, uniq: boolean) => {
    const newFilters = { ...filter.selected };
    if (!newFilters[field] || uniq) {
      dispatch(selectFilterAction({ [field]: [event.target.value.toString()] }));
      return;
    }
    if (!uniq && !newFilters[field].find((value) => event.target.value.toString() === value)) {
      dispatch(selectFilterAction({ [field]: [...newFilters[field], event.target.value.toString()] }));
      return;
    }
    dispatch(
      selectFilterAction({ [field]: newFilters[field].filter((value) => event.target.value.toString() !== value) })
    );
  };

  const handleAutocompleteChange = (valueAutocomplete: FilterItemOption | null, field: string) => {
    const newFilters = { ...filter.selected };
    if (!valueAutocomplete)
      if (!newFilters[field]) {
        dispatch(selectFilterAction({ [field]: [] }));
        return;
      }
    if (valueAutocomplete) {
      dispatch(selectFilterAction({ [field]: [valueAutocomplete.value.toString()] }));
      return;
    }
    dispatch(selectFilterAction({ [field]: [] }));
  };

  const renderSelect = (options: FilterItemOption[], field: string, inputLabel?: string, uniq = false) => {
    const uniqSelectedValue = options.find((o) => filter.selected?.[field]?.[0] === o?.value?.toString() ?? '') ?? '';
    return (
      <FormControl id={field} fullWidth sx={{ mb: 1 }}>
        <InputLabel id={`select-${field}`}>{inputLabel || `Select ${field}`}</InputLabel>
        <Select
          label={inputLabel || `Select ${field}`}
          labelId={`select-${field}`}
          value={uniq ? uniqSelectedValue : ''}
          onChange={(event) => handleFilterChange(event, field, uniq)}
          renderValue={(value) =>
            typeof value === 'string' ? (
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Typography>{value}</Typography>
                {value && (
                  <Typography sx={{ ml: 'auto', color: 'text.secondary' }} variant="caption">
                    {value}
                  </Typography>
                )}
              </Box>
            ) : (
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Typography>{value.label}</Typography>
                {value && (
                  <Typography sx={{ ml: 'auto', color: 'text.secondary' }} variant="caption">
                    {value.sublabel}
                  </Typography>
                )}
              </Box>
            )
          }
        >
          {options.map((option) => (
            <MenuItem value={option.value} key={JSON.stringify(option)} sx={{ display: 'flex', alignItems: 'center' }}>
              <Typography>{option.label}</Typography>
              {option.sublabel && (
                <Typography sx={{ ml: 'auto', color: 'text.secondary' }} variant="caption">
                  {option.sublabel}
                </Typography>
              )}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    );
  };
  const renderAutocomplete = (options: FilterItemOption[], field: string, inputLabel?: string, uniq = false) => {
    const selectedValue = options.find((o) => filter.selected?.[field]?.[0] === o?.value?.toString() ?? '');
    return (
      <>
        <Typography>{inputLabel}</Typography>
        <Autocomplete
          key={inputKey}
          options={options}
          onChange={(event, value) => handleAutocompleteChange(value, field)}
          placeholder={inputLabel || `Select ${field}`}
          value={selectedValue}
          fullWidth
          renderOption={(props, value) =>
            typeof value !== 'string' && (
              <Box component="li" {...props} key={JSON.stringify(value)} sx={{ display: 'flex', alignItems: 'center' }}>
                <Typography>{value.label}</Typography>
                {value && (
                  <Typography sx={{ ml: 'auto', color: 'text.secondary' }} variant="caption">
                    {value.sublabel}
                  </Typography>
                )}
              </Box>
            )
          }
          disableClearable
          sx={{ mb: 1 }}
          renderInput={(params) => (
            <TextField
              {...params}
              variant="outlined"
              fullWidth
              inputProps={{
                autoComplete: 'new-password',
                ...params.inputProps,
              }}
            />
          )}
        />
      </>
    );
  };

  const renderMultiSelect = (items: any[]) => (
    <>{items.map((i) => renderSelect(i.options, i.field, i.inputLabel, i.uniq))} </>
  );
  const renderMultiAutocomplete = (items: any[]) => (
    <>{items.map((i) => renderAutocomplete(i.options, i.field, i.inputLabel, i.uniq))} </>
  );

  const renderCheckboxes = (options: string[] | FilterItemOption[], field: string, displayDirection: string) => (
    <FormGroup className={`direction-${displayDirection}`}>
      {options.map((option: string | FilterItemOption) => {
        const optionValue = isOptionItem(option) ? (option as FilterItemOption).value : option;
        return (
          <FormControlLabel
            control={<Checkbox onChange={(event) => handleCheckboxChanges(event, field)} value={optionValue ?? null} />}
            checked={filter.selected?.[field]?.includes(optionValue) ?? false}
            key={JSON.stringify(option)}
            label={isOptionItem(option) ? <span>{(option as FilterItemOption).label} </span> : <span>{option}</span>}
          />
        );
      })}
    </FormGroup>
  );

  const isOptionItem = (option: string | FilterItemOption) =>
    (option as FilterItemOption).value !== undefined && (option as FilterItemOption).label !== undefined;

  const handleCheckboxChanges = (event: any, field: string) => {
    const newFilters = { ...filter.selected };
    if (!newFilters[field] && event.target.checked) {
      dispatch(selectFilterAction({ [field]: [event.target.value] }));
      return;
    }
    if (newFilters[field] && event.target.checked) {
      if (!newFilters[field].includes(event.target.value)) {
        dispatch(
          selectFilterAction({
            [field]: filter.selected?.[field]
              ? filter.selected?.[field]?.concat([event.target.value])
              : [event.target.value],
          })
        );
        return;
      }
    }

    if (newFilters[field] && !event.target.checked) {
      dispatch(
        selectFilterAction({ [field]: filter.selected?.[field]?.filter((value) => value !== event.target.value) ?? [] })
      );
      return;
    }
    dispatch(selectFilterAction({ [field]: [] }));
  };

  // const handleDateFilterChange = (
  //   field: string,
  //   date: Moment | null,
  //   isSingleValue = false,
  //   otherRangeFieldName = '',
  //   updateSolo = false
  // ) => {
  //   const dateStr = date ? date.format('YYYY-MM-DD') : '';
  //   if ((date && !isSingleValue) || updateSolo) {
  //     if (!filter.selected?.[field] || (filter.selected?.[field] && updateSolo)) {
  //       dispatch(selectFilterAction({ [field]: [dateStr] }));
  //       return;
  //     }
  //     if (filter.selected?.[field] && !filter.selected?.[field].find((value) => dateStr === value)) {
  //       dispatch(selectFilterAction({ [field]: [...(filter.selected?.[field] || []), dateStr] }));
  //       return;
  //     }
  //   } else if (isSingleValue) {
  //     if (filter.selected && !filter.selected?.[otherRangeFieldName] && dateStr) {
  //       dispatch(selectFilterAction({ [field]: [dateStr] }));
  //       return;
  //     }

  //     if (dateStr === '' || ((!date || dateStr === '') && filter.selected?.[field])) {
  //       dispatch(selectFilterAction({ [field]: [] }));
  //       return;
  //     }
  //   }
  //   dispatch(selectFilterAction({ [field]: [dateStr] }));
  // };

  const handleDateFilterChange = (
    field: string,
    date: Moment | [Moment, Moment] | null,
    isSingleValue = false,
    otherRangeFieldName = '',
    updateSolo = false
  ) => {
    // console.log('handleDateFilterChange called');

    if (Array.isArray(date)) {
      // console.log('entering 2');
      const [startDate, endDate] = date;
      const startDateStr = startDate ? startDate.format('YYYY-MM-DD') : '';
      const endDateStr = endDate ? endDate.format('YYYY-MM-DD') : '';

      if (startDateStr || endDateStr) {
        // console.log('entering 3');
        dispatch(
          selectFilterAction({
            [`${field}_start`]: startDateStr ? [startDateStr] : [],
            [`${field}_end`]: endDateStr ? [endDateStr] : [],
          })
        );
      } else {
        // console.log('entering 4');
        dispatch(
          selectFilterAction({
            [`${field}_start`]: [],
            [`${field}_end`]: [],
          })
        );
      }
    } else {
      // Handle single date
      // console.log('entering 5');

      const dateStr = date ? date.format('YYYY-MM-DD') : '';

      if ((date && !isSingleValue) || updateSolo) {
        // console.log('entering 5.1');
        if (!filter.selected?.[field] || (filter.selected?.[field] && updateSolo)) {
          // console.log('entering 5.2');
          dispatch(
            selectFilterAction({
              [`${field}_start`]: [dateStr],
              [`${field}_end`]: [],
            })
          );

          if (dateStr === '') {
            dispatch(
              selectFilterAction({
                [`${field}_start`]: [],
                [`${field}_end`]: [],
              })
            );
          }
        }
        if (filter.selected?.[field] && !filter.selected?.[field].find((value) => dateStr === value)) {
          // console.log('entering 5.3');
          dispatch(
            selectFilterAction({
              [`${field}_start`]: [...(filter.selected?.[field] || []), dateStr],
              [`${field}_end`]: [],
            })
          );
        }
      } else if (isSingleValue) {
        // console.log('entering 6');
        if (filter.selected && !filter.selected?.[otherRangeFieldName] && dateStr) {
          dispatch(
            selectFilterAction({
              [`${field}_start`]: [dateStr],
              [`${field}_end`]: [],
            })
          );
        }

        if (dateStr === '' || ((!date || dateStr === '') && filter.selected?.[field])) {
          // console.log('entering 7');
          dispatch(
            selectFilterAction({
              [`${field}_start`]: [],
              [`${field}_end`]: [],
            })
          );
        }
      }
    }
  };

  const transformDateRange = (
    start: string | Moment | null | undefined,
    end: string | Moment | null | undefined
  ): Moment | [Moment, Moment] | null => {
    const startDate = start ? moment(start) : null;
    const endDate = end ? moment(end) : null;

    if (startDate && endDate) {
      return [startDate, endDate];
    }
    if (startDate) {
      return startDate;
    }
    if (endDate) {
      return endDate;
    }
    return null;
  };

  const renderDate = (field: string, rangeDate = false, classNameLabel = '', updateSolo = false) => (
    <LocalizationProvider dateAdapter={MomentUtils}>
      {!rangeDate ? (
        <DesktopDatePicker
          label={dateFormat}
          value={null}
          inputFormat={dateFormat}
          onChange={(date) => handleDateFilterChange(field, date)}
          renderInput={(params) => <TextField {...params} />}
        />
      ) : (
        <Grid container spacing={4}>
          {/* <Grid item xs={12} md={6}>
            <InputDate
              label={t('From')}
              name="start_date"
              id="start_date"
              placeholder="DD/MM/YYYY"
              onchange={(name, date) =>
                handleDateFilterChange(`${field}_start`, date, true, `${field}_end`, updateSolo)
              }
              value={filter.selected && filter.selected[`${field}_start`] ? filter.selected[`${field}_start`][0] : null}
              canEmpty
              classNameLabel={classNameLabel}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <InputDate
              label={t('To')}
              name="end_date"
              id="end_date"
              placeholder="DD/MM/YYYY"
              onchange={(name, date) =>
                handleDateFilterChange(`${field}_end`, date, true, `${field}_start`, updateSolo)
              }
              value={filter.selected && filter.selected[`${field}_end`] ? filter.selected[`${field}_end`][0] : null}
              canEmpty
              classNameLabel={classNameLabel}
            />
          </Grid> */}
          <Grid item xs={8} md={8} sx={{ width: '50%' }}>
            <InputDateRange
              field={field}
              label=""
              name="dateRangePicker"
              id="date-range-picker"
              placeholder={dateFormat}
              onchange={(name, date) => handleDateFilterChange(`${field}`, date, true, `${field}`, updateSolo)}
              value={transformDateRange(
                filter.selected[`${field}_start`] ? filter.selected[`${field}_start`][0] : null,
                filter.selected[`${field}_end`] ? filter.selected[`${field}_end`][0] : null
              )}
              ref={dateRangeRef}
            />
          </Grid>
        </Grid>
      )}
    </LocalizationProvider>
  );

  const filterInputRenderer = (
    inputType: string,
    options: FilterItemOption[] | string[] = [],
    field: string,
    displayDirection: string,
    inputLabel?: string,
    rangeDate?: boolean,
    uniq?: boolean,
    classNameLabel?: string,
    updateSolo?: boolean,
    multiple: MultipleFilterType[] = []
  ): JSX.Element => {
    const inputTypeRenderer: inputRenderer = {
      checkbox: renderCheckboxes(options, field, displayDirection),
      select: renderSelect(options as FilterItemOption[], field, inputLabel, uniq),
      date: renderDate(field, rangeDate, classNameLabel, updateSolo),
      multi_select: renderMultiSelect(multiple),
      multi_autocomplete: renderMultiAutocomplete(multiple),
    };
    // return inputType === 'select' ? inputTypeRenderer[inputType] : <></>;
    return inputTypeRenderer[inputType];
  };

  const getLabelFromOptions = (value: string, options: FilterItemOption[] | undefined) => {
    if (options) {
      const foundOption = options.find((option) => option.value?.toString() === value);
      if (foundOption) {
        return foundOption.label ? foundOption.label : value;
      }
    }
    return value;
  };

  const removeItemFromFilters = (valueIndex: number, field: string) => {
    const selectedFiltersCopy = { ...filter.selected };
    if (selectedFiltersCopy[field].length === 1) {
      selectedFiltersCopy[field] = [];
    } else {
      selectedFiltersCopy[field].splice(valueIndex, 1);
    }
    dispatch(selectFilterAction({ [field]: selectedFiltersCopy[field] }));
    setInputKey((prevKey) => prevKey + 1);
  };

  const handleAccordionChange = (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
    setExpanded(isExpanded ? panel : false);
  };

  const removeItemsFromLocalStorage = () => {
    localStorage.removeItem('date_mode');
    localStorage.removeItem('date_view');
    localStorage.removeItem('delivery_date_mode');
    localStorage.removeItem('delivery_date_view');
    localStorage.removeItem('forecast_date_mode');
    localStorage.removeItem('forecast_date_view');
  };

  const clearSelectedFilter = () => {
    removeItemsFromLocalStorage();
    if (dateRangeRef.current) {
      dateRangeRef.current.clear(); // Calling the clear method
    }
    dispatch(clearSelectedFilterAction());
    setInputKey((prevKey) => prevKey + 1);
  };

  useEffect(() => {
    setActiveFilters(
      Object.entries(filter.selected).filter(
        ([key, value]) =>
          key !== 'date_end' && key !== 'delivery_date_end' && key !== 'forecast_date_end' && value && value.length > 0
      ) as ActiveFilter[]
    );
  }, [filter.selected]);

  const resetallSelectedFilter = () => {
    dispatch(resetAllFilterAction());
  };

  const getFilterNameByFieldKey = (fieldKey: string): string => {
    if (fieldKey === 'date_start') {
      return 'Dates';
    }
    if (fieldKey === 'delivery_date_start') {
      return 'Initial delivery date';
    }
    if (fieldKey === 'forecast_date_start') {
      return 'New expected delivery date';
    }

    const filterItem = filter.items.find((item) => item.field === fieldKey);
    return filterItem ? filterItem.filterName : fieldKey;
  };

  return (
    <Drawer
      id="drawer-filter-container"
      anchor="right"
      open={isOpen}
      onClose={onClose}
      PaperProps={{
        sx: { width: { xs: '100%', md: 600 } },
      }}
    >
      <Box sx={{ display: 'flex', alignItems: 'center', mb: 5 }}>
        <Typography variant="h2" sx={{ mr: 1 }}>
          {t('Filters')}
        </Typography>
        <Button startIcon={<RefreshIcon />} onClick={clearSelectedFilter}>
          {t('reset_all')}
        </Button>
        <IconButton sx={{ ml: 'auto' }} color="primary" onClick={onClose}>
          <Close />
        </IconButton>
      </Box>
      {filter.items.map((filterItem) =>
        filterItem.isVisible ? (
          <Accordion
            key={filterItem.field}
            disableGutters
            square
            elevation={0}
            expanded={
              expanded === filterItem.field ||
              (filterItem.inputType === 'date' &&
                expanded === 'dateFilter' &&
                (filterItem.filterName === 'Initial delivery date' || filterItem.filterName === 'Dates'))
            }
            onChange={handleAccordionChange(filterItem.field)}
          >
            <AccordionSummary expandIcon={<ExpandMore />} aria-controls="panel1a-content" id="panel1a-header">
              <Typography
                sx={{
                  color: (() => {
                    const isActive = activeFilters.some(
                      ([fieldKey]) => getFilterNameByFieldKey(fieldKey) === filterItem.filterName
                    );

                    if (isActive || (filter.selected && filter.selected[filterItem.field]?.length > 0)) {
                      return 'green';
                    }
                    return 'inherit';
                  })(),
                }}
              >
                {filterItem.filterName}
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              {filterInputRenderer(
                filterItem.inputType,
                filterItem.options,
                filterItem.field,
                filterItem.optionsDisplayDirection ? filterItem.optionsDisplayDirection : 'column',
                filterItem.inputLabel,
                filterItem.rangeDate,
                filterItem.uniq ?? false,
                filterItem.classNameLabel ?? '',
                filterItem.updateSolo ?? false,
                filterItem.multiple ?? []
              )}
              {filter.selected && filter.selected[filterItem.field] && filterItem.isVisible && (
                <>
                  {filterItem.inputType !== 'checkbox' &&
                    filter.selected &&
                    filter.selected[filterItem.field].map((value, key) => (
                      <Chip
                        onDelete={() => removeItemFromFilters(key, filterItem.field)}
                        color="primary"
                        label={
                          filterItem.inputType === 'date'
                            ? new Date(value).toLocaleDateString('fr')
                            : (getLabelFromOptions(value, filterItem.options as FilterItemOption[]) as any)
                        }
                        sx={{ mr: 1, mb: 1 }}
                      />
                    ))}
                </>
              )}
            </AccordionDetails>
          </Accordion>
        ) : null
      )}
    </Drawer>
  );
};

export default DrawerFilters;
