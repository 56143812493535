import { Menu, ArrowBackIos } from '@mui/icons-material';
import { Box, IconButton, LinearProgress, Typography, Button } from '@mui/material';
import { capitalize } from 'helpers/format';
import React, { Dispatch, SetStateAction, useState } from 'react';
import { styled } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'store/hooks';
import User from 'types/entities/User';

import UserTopBar from './UserTopBar';

import { useHistory } from 'react-router-dom';

interface Props {
  name: string;
  isWelcome: boolean;
  subTitleElement?: React.ReactNode;
  handleClick: Dispatch<SetStateAction<boolean>>;
  isOpen: boolean;
}

const MenuButton = styled(IconButton)(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  color: '#FFFFFF',
  '&:hover': {
    backgroundColor: theme.palette.primary.dark,
  },
}));

const Topbar: React.FC<Props> = ({ name, isWelcome, subTitleElement, handleClick, isOpen }) => {
  const appState = useSelector((state) => state.app);
  const loadings = useSelector((state) => state.loadings);
  const [t] = useTranslation();
  const history = useHistory();
  const userIsConsultant = appState.roles?.toString().includes('consultant');
  const userIsCustomer = appState.user?.is_client; // TODO use customer roles ?
  let conditionForlink = true;

  if ((userIsConsultant || userIsCustomer) && subTitleElement === undefined) {
    conditionForlink = true;
  } else {
    conditionForlink = false;
  }

  if (!userIsConsultant && !userIsCustomer) {
    conditionForlink = true;
  }

  const handleLayoutActionButton = () => (
    <Button onClick={processUrls} startIcon={<ArrowBackIos />}>
      {conditionForlink && (userIsConsultant || userIsCustomer) ? t('Back') : t('Management')}
    </Button>
  );

  const processUrls = () => {
    history.goBack();
  };

  return (
    <Box
      sx={{
        mx: 4,
        mt: 4,
        pb: 4,
        display: 'flex',
        flexDirection: { md: 'row-reverse', xs: 'column' },
        justifyContent: 'space-between',
        position: 'relative',
      }}
    >
      <Box sx={{ display: 'flex', alignItems: 'center', mb: { xs: 2, md: 0 } }}>
        <Box>
          <MenuButton onClick={() => handleClick(!isOpen)} size="large" sx={{ display: { xs: 'flex', md: 'none' } }}>
            <Menu />
          </MenuButton>
        </Box>

        {appState.user ? <UserTopBar activeUser={appState.user as User} /> : <></>}

        {loadings.api ? (
          <Box sx={{ position: 'absolute', left: 0, right: 0, bottom: 0 }} className="topbar-progress">
            <LinearProgress style={{ height: '3px' }} />
          </Box>
        ) : (
          <></>
        )}
      </Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: { xs: 'column', md: 'row' },
          alignItems: { xs: 'initial', md: 'center' },
        }}
      >
        <Typography
          data-testid="topbar-title"
          variant="h1"
          color="neutral.main"
          sx={{ fontSize: { xs: '2.2rem', md: '2.5rem' }, fontWeight: 700, mr: { xs: 0, md: 2 }, mb: { xs: 1, md: 0 } }}
        >
          {appState.user && appState.user.first_name && isWelcome
            ? `${t('Welcome_back')} ${capitalize(appState.user?.first_name as string)}`
            : name}
        </Typography>
        {subTitleElement && <Box>{subTitleElement}</Box>}
        {conditionForlink && (name === 'Deliverables' || name === 'Affectation') && handleLayoutActionButton()}
      </Box>
    </Box>
  );
};
export default Topbar;
