import { AddBox, Assignment, Check, Outbox, Remove, Warning } from '@mui/icons-material';
import { Avatar, Box, MenuItem, Typography } from '@mui/material';
import NotificationApiService from 'api/NotificationApiService';
import moment from 'moment';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { setSnackbarAction } from 'store/actions/snackbarActions';
import { useAppState } from 'store/Provider';
import { theme } from 'styles/theme';
import DOMPurify from 'dompurify';

type NotificationItemProps = {
  alert: any;
  setAlerts: (id: number) => void;
  closeMenu: () => void;
};
const NotificationItem = (props: NotificationItemProps) => {
  const { alert, setAlerts, closeMenu } = props;
  const { dispatch } = useAppState();
  const [t] = useTranslation();
  const history = useHistory();
  const sanitizedWording = DOMPurify.sanitize(alert.resource_wording, {
    ALLOWED_TAGS: [],
    ALLOWED_ATTR: [],
  });
  const sanitizedName = DOMPurify.sanitize(alert.resource_name, {
    ALLOWED_TAGS: [],
    ALLOWED_ATTR: [],
  });
  const handleClickAlert = async () => {
    try {
      await NotificationApiService.Instance.setAlertRead(alert);
      setAlerts(alert.id);
      closeMenu();
    } catch (error) {
      dispatch(setSnackbarAction({ message: 'Error with notifications', open: true, severity: 'error' }));
    }
    history.push(alert.resource_uri);
  };

  const getNotificationIcon = () => (
    <Avatar
      sx={{
        ...(alert.resource_name === 'mission_refusal_message' && {
          backgroundColor: theme.palette.error.main,
        }),
        ...((alert.resource_name === 'mission_just_shipped_message' ||
          alert.resource_name === 'mission_just_confirmed' ||
          alert.resource_name === 'scope_new_assignation_message') && {
          backgroundColor: theme.palette.primary.main,
        }),
        ...(alert.resource_name === 'mission_bad_ranking_message' && {
          backgroundColor: theme.palette.error.dark,
        }),
        ...(alert.resource_name === 'mission_new_consultant_assignation_message' && {
          backgroundColor: theme.palette.success.dark,
        }),
        ...(alert.resource_name === 'mission_accept_partial_message' && {
          backgroundColor: theme.palette.warning.main,
        }),
      }}
    >
      {alert.resource_name === 'mission_refusal_message' && <Remove />}
      {alert.resource_name === 'mission_just_shipped_message' && <Outbox />}
      {alert.resource_name === 'mission_bad_ranking_message' && <Warning />}
      {alert.resource_name === 'mission_just_confirmed' && <AddBox />}
      {(alert.resource_name === 'mission_new_consultant_assignation_message' ||
        alert.resource_name === 'scope_new_assignation_message') && <Assignment />}
      {alert.resource_name === 'mission_accept_partial_message' && <Check />}
    </Avatar>
  );

  const getNotificationTitle = () => (
    <Typography
      variant="h6"
      sx={{
        ...(alert.resource_name === 'mission_refusal_message' && {
          color: theme.palette.error.main,
        }),
        ...((alert.resource_name === 'mission_just_shipped_message' ||
          alert.resource_name === 'mission_just_confirmed' ||
          alert.resource_name === 'scope_new_assignation_message') && {
          color: theme.palette.primary.main,
        }),
        ...(alert.resource_name === 'mission_bad_ranking_message' && {
          color: theme.palette.error.dark,
        }),
        ...(alert.resource_name === 'mission_new_consultant_assignation_message' && {
          color: theme.palette.success.dark,
        }),
        ...(alert.resource_name === 'mission_accept_partial_message' && {
          color: theme.palette.warning.main,
        }),
      }}
    >
      <div>{sanitizedName}</div>
    </Typography>
  );

  return (
    <MenuItem
      onClick={() => handleClickAlert()}
      key={alert.id}
      sx={{ mx: 2, my: 1, py: 2, borderRadius: '10px', whiteSpace: 'initial', display: 'block' }}
    >
      <Box
        sx={{ display: 'flex', justifyContent: 'space-between' }}
        className={`notification-container ${alert.resource_name && !alert.is_read ? alert.resource_name : ''} `}
      >
        <Box sx={{ display: 'flex', mr: 2 }}>
          <Box sx={{ mr: 2 }}>{getNotificationIcon()}</Box>
          <Box>
            {getNotificationTitle()}
            <Typography color="neutral.dark" variant="body2">
              <div>{sanitizedWording}</div>
            </Typography>
          </Box>
        </Box>
        <Box sx={{ textAlign: 'right', whiteSpace: 'nowrap' }}>
          <Typography variant="caption" color="neutral.dark">
            {moment(alert.created_at).fromNow()}
          </Typography>
        </Box>
      </Box>
    </MenuItem>
  );
};

export default NotificationItem;
