import { RemoveRedEye } from '@mui/icons-material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { IconButton, ListItemIcon, ListItemText, Menu, MenuItem, MenuList } from '@mui/material';
import ResourceAPI from 'api/ResourceAPI';
import UserApiService from 'api/UserApiService';
import { Loader } from 'components';
import { ArchiveIcon, EditIcon, KeyIcon, ResetPasswordIcon, RestoreIcon, DeleteIcon } from 'components/CustomIcons';
import useClientsRights from 'hooks/rights/useClientsRights';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { openDialogAction } from 'store/actions/dialogsActions';
import { setSnackbarAction } from 'store/actions/snackbarActions';
import { useSelector } from 'store/hooks';
import { useAppState } from 'store/Provider';
import Role from 'types/entities/Role';
import User from 'types/entities/User';
import useApi from 'hooks/useApi';

interface Props {
  activeClient: User;
  dispatchEditOpen: React.DispatchWithoutAction;
  setActiveClient: (client: User) => void;
  handleDeleteclient: (userId: number) => void;
  setClients: React.Dispatch<React.SetStateAction<User[]>>;
}

const ClientMenuActions: React.FC<Props> = ({
  activeClient,
  dispatchEditOpen,
  setActiveClient,
  handleDeleteclient,
  setClients,
}) => {
  const { dispatch } = useAppState();
  const appState = useSelector((state) => state.app);
  const { canEdit } = useClientsRights();
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [isClose, setIsClose] = useState<boolean>(false);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const { makeCall } = useApi();

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setIsClose(!isClose);
  };

  useEffect(() => {
    const closeAnchorE1 = () => {
      setAnchorEl(null);
    };
    return () => {
      closeAnchorE1();
    };
  }, [isClose]);

  const editClient = (userSelected: User) => {
    setActiveClient(userSelected);
    dispatchEditOpen();
    handleClose();
  };

  const restoreUser = async () => {
    try {
      if (activeClient && activeClient.id) {
        const { user_client } = await makeCall(
          UserApiService.updateClient(activeClient.id, {
            is_archived: false,
          }),
          'Unable to restore client'
        );

        if (user_client) {
          dispatch(setSnackbarAction({ message: t('restore_success'), open: true, severity: 'success' }));
          setActiveClient(user_client);
          handleDeleteclient(activeClient.id);
        } else {
          dispatch(setSnackbarAction({ message: 'Unable to restore client', open: true, severity: 'error' }));
        }
      }
    } catch (e) {
      const result = (e as Error).message;
      if (result) {
        dispatch(setSnackbarAction({ message: `Failed to restore user: ${result}`, open: true, severity: 'error' }));
      } else {
        dispatch(setSnackbarAction({ message: `Failed to restore user :${e}`, open: true, severity: 'error' }));
      }
    }

    handleClose();
  };

  const deleteClient = async () => {
    try {
      if (activeClient && activeClient.id) {
        await ResourceAPI.delete('users/clients', activeClient.id);
        handleDeleteclient(activeClient.id);

        // archived message
        dispatch(setSnackbarAction({ message: `The user is archived successfully.`, open: true, severity: 'success' }));
      }
    } catch (e) {
      const result = (e as Error).message;
      if (result) {
        dispatch(setSnackbarAction({ message: `Failed to archive user: ${result}`, open: true, severity: 'error' }));
      } else {
        dispatch(setSnackbarAction({ message: `Failed to archive user :${e}`, open: true, severity: 'error' }));
      }
    }
    setActiveClient(activeClient);
    handleClose();
  };

  const [t] = useTranslation();
  const [isCognitoLoading, setIsCognitoLoading] = useState<boolean>(false);
  const createCognitoForClient = async () => {
    setIsCognitoLoading(true);
    try {
      if (activeClient && activeClient.id && appState.customer) {
        const response = await UserApiService.openAccess(activeClient.id, activeClient.mail);

        if (response) {
          const {
            data: { datas },
          } = await ResourceAPI.fetchAll('roles', '?size=-1');
          await UserApiService.updateUserRole(
            appState.customer.id,
            (datas.find((r: Role) => r.name === 'customer') as Role).id as number
          );
        }

        setIsOpen(true);
        dispatch(
          setSnackbarAction({ message: `Created cognito account with success`, open: true, severity: 'success' })
        );
      }
    } catch (e) {
      const result = (e as Error).message;
      if (result) {
        dispatch(
          setSnackbarAction({ message: `Unable to create cognito account : ${result}`, open: true, severity: 'error' })
        );
      } else {
        dispatch(setSnackbarAction({ message: `Unable to create cognito account `, open: true, severity: 'error' }));
      }
    } finally {
      setIsCognitoLoading(false);
    }
    handleClose();
  };

  const resetClientPassword = (userSelected: User) => {
    setActiveClient(userSelected);
    dispatch(openDialogAction({ name: 'clientResetPassword' }));
  };

  const deactivateUser = (userSelected: User) => {
    setActiveClient(userSelected);
    dispatch(openDialogAction({ name: 'clientDeactivateUser' }));
  };

  return (
    <>
      <IconButton
        aria-label="settings"
        aria-controls="long-menu"
        aria-haspopup="true"
        onClick={handleClick}
        color="primary"
        size="large"
        data-testid="client-menu-settings"
      >
        <MoreVertIcon />
      </IconButton>
      <Menu id="long-menu" anchorEl={anchorEl} keepMounted open={open} onClose={handleClose}>
        <MenuList>
          {/* eslint-disable-next-line max-len */}
          {canEdit && activeClient.cognito_status === 'disable' && !isOpen && !activeClient.is_archived && (
            <MenuItem
              onClick={createCognitoForClient}
              key={`menuitem_trash${Math.random() * 1100000}`}
              data-testid="client_create_cognito"
            >
              {!isCognitoLoading ? (
                <>
                  <ListItemIcon>
                    <KeyIcon fontSize="small" sx={{ color: 'text.primary' }} />
                  </ListItemIcon>
                  <ListItemText>{t('Open_access')}</ListItemText>
                </>
              ) : (
                <Loader size={20} />
              )}
            </MenuItem>
          )}

          {canEdit &&
            !activeClient.last_connection &&
            !activeClient.is_archived &&
            (activeClient.cognito_status === 'enable' ||
              activeClient.cognito_status === 'change_password' ||
              isOpen) && (
              <MenuItem
                onClick={() => resetClientPassword(activeClient)}
                key={`menuitem_trash${Math.random() * 1100000}`}
                data-testid="client_reset_password"
              >
                <ListItemIcon>
                  <ResetPasswordIcon fontSize="small" sx={{ color: 'text.primary' }} />
                </ListItemIcon>
                <ListItemText>{t('reset_password')}</ListItemText>
              </MenuItem>
            )}

          {!activeClient.is_archived && (
            <MenuItem
              onClick={() => editClient(activeClient)}
              key={`menuitem_edit${Math.random() * 1100000}`}
              data-testid="client_edit"
            >
              {canEdit ? (
                <>
                  <ListItemIcon>
                    <EditIcon fontSize="small" sx={{ color: 'text.primary' }} />
                  </ListItemIcon>
                  <ListItemText>{t('Edit')}</ListItemText>
                </>
              ) : (
                <>
                  <ListItemIcon>
                    <RemoveRedEye fontSize="small" sx={{ color: 'text.primary' }} />
                  </ListItemIcon>
                  <ListItemText>{t('details')}</ListItemText>
                </>
              )}
            </MenuItem>
          )}

          {canEdit &&
            (!activeClient.is_archived ? (
              <MenuItem
                onClick={deleteClient}
                key={`menuitem_trash${Math.random() * 1100000}`}
                datat-testid="client_delete"
              >
                <ListItemIcon>
                  <ArchiveIcon fontSize="small" sx={{ color: 'text.primary' }} />
                </ListItemIcon>
                <ListItemText>{t('archive')}</ListItemText>
              </MenuItem>
            ) : (
              <MenuItem
                onClick={restoreUser}
                key={`menuitem_trash${Math.random() * 1100000}`}
                datat-testid="client_delete"
              >
                <ListItemIcon>
                  <RestoreIcon fontSize="large" sx={{ color: 'text.primary' }} />
                </ListItemIcon>
                <ListItemText>{t('restore_account')}</ListItemText>
              </MenuItem>
            ))}

          {canEdit && !activeClient.is_archived && (
            <MenuItem
              onClick={() => deactivateUser(activeClient)}
              key={`menuitem_deactivate_user${Math.random() * 1100000}`}
              data-testid="client_deactivate_user"
            >
              <ListItemIcon>
                <DeleteIcon fontSize="small" sx={{ color: 'red' }} />
              </ListItemIcon>
              <ListItemText sx={{ color: 'red' }}>{t('deactivate')}</ListItemText>
            </MenuItem>
          )}
        </MenuList>
      </Menu>
    </>
  );
};

export default ClientMenuActions;
